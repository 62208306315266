import React from 'react';
import Navbar from '../components/navbar';

export default function TC() {
  return (
    <>
      <Navbar />
      <div className="px-8 lg:px-28 text-justify">
        <p className="py-2 lg:py-12 text-md text-[#1c1e21ad]">
          <h1 className="text-center text-2xl font-black text-[#16496F] py-1 underline">
            Terms & Conditions
          </h1>
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Eligibility
          </h1>
          <hr className="w-auto py-2" />
          For the purposes of availing the Services and/or transacting with the
          Third Party Service Providers through the Website, You are required to
          obtain registration, in accordance with the procedure established by
          VYLOO TECHNOLOGIES PRIVATE LIMITED ("VYLOO", “VYLOO TECHNOLOGIES or
          "WE") in this regard. As part of the registration process, VYLOO
          TECHNOLOGIES may collect the following personal information from You:{' '}
          <br />
          1. Name; <br />
          2. User ID; <br />
          3. Email address;
          <br />
          4. Address (including country and ZIP/ postal code); <br />
          5. Gender; <br />
          6. Age; <br />
          7. Phone number; <br />
          8. Password chosen by the User; <br />
          9. Valid financial account information; and <br />
          10. Other details as You may volunteer. <br />
          11. The registration on or use/ access of the Website is only
          available to natural persons, other than those who are ‘incompetent to
          contract’ under the Contract Act. That is, persons including minors,
          un-discharged insolvents etc. are not eligible to register on, or use/
          access the Website. By registering, accessing or using the Website,
          You accept the terms of these Terms of Use and represent and warrant
          to VYLOO TECHNOLOGIES that you are ‘competent to contract’ under the
          Contract Act and have the right, authority and capacity to use the
          Website and agree to and abide by these Terms of Use. <br />
          12. A registered id can only be utilized by the person whose details
          have been provided and VYLOO TECHNOLOGIES does not permit multiple
          persons to share a single log in registration id. However, a
          registered user, being also a parent or legal guardian of a person
          ‘incompetent to contract’ such as minors or persons with unsound mind,
          would be permitted to access and use the Website for the purposes of
          procuring the Services, on behalf of such persons. <br />
          13. Organizations, companies, and businesses may not become registered
          members on the Website or use the Website through individual members.{' '}
          <br />
          14. You agree and acknowledge that You would (i) create only 1 (one)
          account; (ii) provide accurate, truthful, current and complete
          information when creating Your account and in all Your dealings
          through the Website; (iii) maintain and promptly update Your account
          information; (iv) maintain the security of Your account by not sharing
          Your password with others and restricting access to Your account and
          Your computer; (v) promptly notify VYLOO TECHNOLOGIES if You discover
          or otherwise suspect any security breaches relating to the Website;
          and (vi) take responsibility for all the activities that occur under
          Your account and accept all risk of unauthorized access. <br />
          15. The Website uses temporary cookies to store certain data (that is
          not sensitive personal data or information) that is used by VYLOO
          TECHNOLOGIES for the technical administration of the Website, research
          and development, and for User administration. In the course of serving
          advertisements or optimizing services to You, VYLOO TECHNOLOGIES may
          allow authorized third parties to place or recognize a unique cookie
          on the Your browser. VYLOO TECHNOLOGIES does not store personally
          identifiable information in the cookies. <br />
          16. VYLOO TECHNOLOGIES, at its sole discretion, reserves the right to
          permanently or temporarily suspend Users, to bar their use and access
          of the Website and App, at any time while VYLOO TECHNOLOGIES
          investigates complaints or alleged violations of these Terms of Use or
          any Services, or for any other reason. <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            {' '}
            USE OF SERVICES AND THE WEBSITE{' '}
          </h1>
          <hr className="w-auto py-2" />
          <div> 1. E-Commerce Platform for Pharmaceutical Products –</div>{' '}
          <br />
          <div className="px-2 lg:px-12">
            1. Platform to facilitate transaction of business:
          </div>{' '}
          <br />
          <div className="px-2 lg:px-20">
            1. Through the Website, VYLOO TECHNOLOGIES facilitates the purchase
            of drugs and other pharmaceutical products, and services offered for
            sale by Third Party Pharmacies (“Pharmaceutical Goods and
            Services”). You understand and agree that VYLOO TECHNOLOGIES and the
            Website merely provide hosting services to You and persons browsing
            / visiting the Website. All items offered for sale on the Website,
            and the content made available by the Third Party Pharmacies, are
            third party user generated contents and third party products. VYLOO
            TECHNOLOGIES has no control over such third party user generated
            contents and/ Pharmaceutical Goods and Services and does not -
            originate or initiate the transmission, or select the
            sender/recipient of the transmission, or the information contained
            in such transmission. The authenticity and genuineness of the
            Pharmaceutical Goods and Services made available by the Third Party
            Pharmacies through the Website shall be the sole responsibility of
            the Third Party Pharmacies. You understand and agree that VYLOO
            TECHNOLOGIES shall have no liability with respect to the
            authenticity of the Pharmaceutical Goods and Services being
            facilitated through the Website.
          </div>{' '}
          <br />
          <div className="px-2 lg:px-20">
            2. You understand and agree that all commercial / contractual terms,
            with respect to the sale/ purchase/ delivery and consumption of the
            Pharmaceutical Goods and Services which are offered by and agreed to
            between You and the Third Party Pharmacies and the contract for
            purchase of any of the Pharmaceutical Goods and Services, which are
            offered for sale on the Website by the Third Party Pharmacies shall
            strictly be a bipartite contract between the Third Party Pharmacies
            and You.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. The commercial / contractual terms include without limitation -
            price, shipping costs, payment methods, payment terms, date, period
            and mode of delivery, warranties related to Pharmaceutical Goods and
            Services offered for sale by the Third Party Pharmacies, and after
            sales services related to such Pharmaceutical Goods and Services are
            as provided by the Third Party Pharmacies. VYLOO TECHNOLOGIES does
            not have any control over, and does not determine or advise or in
            any way involve itself in the offering or acceptance of, such
            commercial / contractual terms offered by and agreed to, between You
            and the Third Party Pharmacies.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            2. Representation as to legal title{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. VYLOO TECHNOLOGIES does not make any representation or warranty
            as to legal title of the Pharmaceutical Goods and Services offered
            for sale by the Third Party Pharmacies on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. At no time shall any right, title, claim or interest in the
            products sold through or displayed on the Website vest with VYLOO
            TECHNOLOGIES nor shall VYLOO TECHNOLOGIES have any obligations or
            liabilities in respect of any transactions on the Website. You agree
            and acknowledge that the ownership of the inventory of such
            Pharmaceutical Goods and Services shall always vest with the Third
            Party Pharmacies, who are advertising or offering them for sale on
            the Website and are the ultimate sellers.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. You agree and acknowledge that the Third Party Pharmacies shall
            be solely responsible for any claim/ liability/ damages that may
            arise in the event it is discovered that such Third Party Pharmacies
            do not have the sole and exclusive legal ownership over the
            Pharmaceutical Goods and Services that have been offered for sale on
            the Website by such Third Party Pharmacies, or did not have the
            absolute right, title and authority to deal in and offer for sale
            such Pharmaceutical Goods and Services on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">3. Non-Performance of Contract </div>
          <br />
          <div className="px-2 lg:px-20">
            1. You accept and acknowledge the following: VYLOO TECHNOLOGIES is
            not responsible for any unsatisfactory, delayed, non-performance or
            breach of the contract entered into between You and the Third Party
            Pharmacies for purchase and sale of goods or services offered by
            such Third Party Pharmacies on the Website;{' '}
          </div>
          <div className="px-2 lg:px-20">
            2. VYLOO TECHNOLOGIES cannot and does not guarantee that the
            concerned Third Party Pharmacies will perform any transaction
            concluded on the Website;
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. The Third Party Pharmacy(s) are solely responsible for ensuring
            that the Pharmaceutical Goods and Services offered for sale on the
            Website are kept in stock for successful fulfillment of orders
            received. Consequently, VYLOO TECHNOLOGIES is not responsible if the
            Third Party Pharmacy(s) does not satisfy the contract for sale of
            Pharmaceutical Goods and Services which are out of stock, back
            ordered or otherwise unavailable, but were shown as available on the
            Website at the time of placement of order by You; and 4. VYLOO
            TECHNOLOGIES shall not and is not required to mediate or resolve any
            dispute or disagreement between You and Third Party Pharmacies. In
            particular, VYLOO TECHNOLOGIES does not implicitly or explicitly
            support or endorse the sale or purchase of any items or services on
            the Website. VYLOO TECHNOLOGIES shall, on a request in writing made
            by You after the purchase of any Pharmaceutical Goods and Services
            on the Website, provide You with information regarding the Third
            Party Pharmacies from which You have made such purchase, including
            the principal geographic address of its headquarters and all
            branches, name and details of its website, its email address and any
            other information necessary for communication with the Third Party
            Pharmacy for dispute resolution.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            4. Exhibition of drugs and publication of Third Party Pharmacies
            content on the Website
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. You agree and acknowledge that the respective Third Party
            Pharmacies are exhibiting Third Party Content which includes
            catalogue of drugs/ pharmaceutical products or services, and
            information in relation to such drugs/ pharmaceutical products or
            services, on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. The Third Party Content available on the Website, including
            without limitation, text, copy, audio, video, photographs,
            illustrations, graphics and other visuals, is for general
            information purposes only and does not constitute either an
            advertisement/ promotion of any drug being offered for sale by the
            Third Party Pharmacies on the Website or any professional medical
            advice, diagnosis, treatment or recommendations of any kind.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. You acknowledge and agree that such Third Party Pharmacies shall
            be solely responsible for ensuring that such Third Party Content
            made available regarding the Pharmaceutical Goods and Services
            offered for sale on the Website, are not misleading and describe the
            actual condition of the Pharmaceutical Goods and Services. In this
            connection, it is solely the responsibility of the concerned Third
            Party Pharmacy(s) to ensure that all such information is accurate in
            all respects and there is no exaggeration or over emphasis on the
            specifics of such Pharmaceutical Goods and Services so as to mislead
            the Users in any manner. You acknowledge and understand that 1 mg
            provides no warranty or representation with respect to the
            authenticity/ veracity of the information provided on the Website
            and You must run Your own independent check. You agree and
            acknowledge that VYLOO TECHNOLOGIES has not played any role in the
            ascertainment of the actual impact/ effect of any Pharmaceutical
            Goods and Services being offered for sale by the Third Party
            Pharmacies on the Website. Further, it is hereby clarified that the
            Third Party Pharmacies are offering the Pharmaceutical Goods and
            Services for sale to You and they are responsible for procuring the
            appropriate licenses for the same under the Drugs Act read with the
            Drug rules and the Pharmacy Act. You agree and acknowledge that You
            shall not hold VYLOO TECHNOLOGIES responsible or liable for any
            damages arising out of such reliance on third party user generated
            content by You.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">5. Prescription Drugs </div>
          <br />
          <div className="px-2 lg:px-20">
            1. The Website is a platform that can be used by the Users to
            purchase various drugs and pharmaceutical products that requires a
            valid medical prescription issued by a medical expert/ doctor to be
            provided to a registered pharmacist for the purpose of dispensing
            such medicine (“Prescription Drugs”), offered for sale on the
            Website by Third Party Pharmacies. In order to purchase Prescription
            Drugs from Third Party Pharmacies through the Website, You are
            required to upload a scanned copy of the valid prescription on the
            Website or email the same to VYLOO TECHNOLOGIES. The order would not
            be processed and forwarded to the concerned Third Party Pharmacy(s)
            by VYLOO TECHNOLOGIES until it receives a copy of a valid
            prescription. Third Party Pharmacies will verify the prescription
            forwarded by You and in case of Third Party Pharmacy(s) observe any
            discrepancy in the prescription uploaded by You, the Third Party
            Pharmacy(s) will cancel the order immediately. You are also required
            to make the original prescription available at the time of receipt
            of delivery of Prescription Drugs. You shall allow the delivery
            agent to stamp the original prescription at the time of medicine
            delivery failing which medicines will not be delivered.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. VYLOO TECHNOLOGIES shall maintain a record of all the
            prescriptions uploaded by the Users.
          </div>{' '}
          <br />
          <div className="px-2 lg:px-12">
            6. Substitution of Prescribed Drugs{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. You acknowledge and accept that the order for a substitute of a
            Prescription Drug would only be processed if the medical expert/
            doctor has himself/ herself permitted for any other equivalent
            generic drug to be dispensed in place of the Prescription Drug in
            the prescription or if the prescription solely lists the salt names
            instead of a specific brand name.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. You further acknowledge and accept that, in the absence of the
            above, the concerned Third Party Pharmacy would not dispense a
            substitute drug in place of the Prescription Drug.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">7. Invitation to offer for sale</div>
          <div className="px-2 lg:px-20">
            1. Notwithstanding anything else contained in any other part of
            these Terms of Use, the listing of drugs and other pharmaceutical
            products on the Website by the Third Party Pharmacies is merely an
            ‘invitation to an offer for sale’ and not an ‘offer for sale’. The
            placement of an order by You shall constitute an offer by You to
            enter into an agreement with the Third Party Pharmacies (“Offer”).
            Post the Offer from the Third Party Pharmacies, VYLOO TECHNOLOGIES
            shall send an email to You with the information on the Offer along
            with the details of the concerned Third Party Pharmacy(s) who may
            undertake the sale, and such an email shall not be considered as an
            acceptance of the Offer. The acceptance of the Offer would only be
            undertaken by the Third Party Pharmacy(s) after the validation/
            verification of the prescription by such Third Party Pharmacy (in
            case of Prescription Drugs) and the ascertainment of the available
            stock in the relevant Third Party Pharmacy(s) (in the case of
            prescription as well as other drugs/ pharmaceutical products), by
            way of a confirmatory email to be sent to You.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. For the avoidance of any doubt, it is hereby clarified that any
            reference of the term ‘offer/ offered for sale by the Third Party
            Pharmacy’, as appearing in these Terms of Use, shall be construed
            solely as an ‘invitation to offer for sale’ by any such Third Party
            Pharmacy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            8. Transfer of Property and Completion of Sale{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. Upon acceptance of the Offer by the concerned Third Party
            Pharmacy (being the brick and mortar pharmacy, the Pharmaceutical
            Drugs and Services would be dispensed at the pharmacy, in accordance
            with the terms of the order placed by You. Such dispensation shall
            also take place under the direct/ personal supervision of the
            pharmacist of the Third Party Pharmacy, wherever required under the
            applicable law(s).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. You agree and acknowledge that the property and title in the
            Pharmaceutical Drugs and Services ordered by You shall stand
            immediately transferred to You upon the dispensation of
            Pharmaceutical Drugs and Services and the raising of the invoice at
            the concerned Third Party Pharmacy. Accordingly, the sale of
            Pharmaceutical Drugs and Services is concluded at the concerned
            Third Party Pharmacy itself.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. The invoice in relation to the Pharmaceutical Drugs and Services,
            that are required to be delivered to You shall be issued by the
            concerned Third Party Pharmacy (being the brick and mortar pharmacy)
            which is to process and satisfy the order for such Pharmaceutical
            Drugs and Services.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">9. Delivery of Drugs </div>
          <br />
          <div className="px-2 lg:px-20">
            1. You agree to appoint an individual who shall act in the capacity
            of Your agent and collect the medicines as requested by You from
            Third Party Pharmacy on Your behalf ("User Agent"). You accept and
            acknowledge that the User Agent shall be responsible to collect the
            medicines ordered by You from the Third Party Pharmacy and to carry
            it to the address notified by You.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. You further agree and acknowledge that the User Agent acts as
            Your agent for collecting the medicines from the Third Party
            Pharmacy. The services are being undertaken by User Agent with Your
            consent and therefore the Company is merely facilitating for You and
            Users Agent to connect.{' '}
          </div>
          <br />
          <div>2. Advertising Guidelines for the Website –</div>
          <br />
          <div className="px-2 lg:px-12">
            1. As part of the Services provided by Us; We facilitate and allow
            Third Party Advertisers to place advertisements on the Website.
            Accordingly, there are guidelines (as listed herein below) which the
            Third Party Advertisers have to follow for placing such
            advertisements (the “Advertising Policy”).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. For the Users: VYLOO TECHNOLOGIES clearly distinguishes between
            the editorial content and content that is created or provided by one
            of Our Third Party Advertisers. The advertisements will be labeled
            as "sponsored", "from our Advertisers" or "advertisement". This
            content will not be reviewed by Our in-house editorial staff and
            shall not be subject to Our editorial policy (as set out herein
            below) but shall be subject to the Advertising Policy, these Terms
            of Use (except the editorial policy) and the Privacy Policy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. For the Third Party Advertisers: The Third Party Advertisers must
            be honest about the products or services their advertisements
            promote; the advertisement shall not create unrealistic expectation
            and must not be misleading or offending; must be responsible and of
            the highest standards and without compromising the consumer
            protection. The Advertising Policy applies to all the
            advertisements, listed or sought to be listed, on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. General Rules: All the advertisements must comply with the
            Advertising Policy, the terms of these Terms of Use (except the
            editorial policy) and the Privacy Policy. VYLOO TECHNOLOGIES may, at
            any time and without having to serve any prior notice to the Third
            Party Advertisers, (i) upgrade, update, change, modify, or improve
            the Website or a part thereof in a manner it may deem fit, and (ii)
            change the content of the Advertising Policy and/ or these Terms of
            Use and/ or the Privacy Policy. It is the responsibility of the
            Third Party Advertisers, in such cases, to review the terms of the
            Advertising Policy and/ or these Terms of Use and/ or the Privacy
            Policy, from time to time. Such change shall be made applicable when
            they are posted. VYLOO TECHNOLOGIES may also alter or remove any
            content from the Website without notice and without liability. The
            Third Party Advertisers are also responsible for ensuring that their
            advertisements comply with all applicable law(s) in India and any
            other jurisdiction that such Third Party Advertiser(s) are based out
            of, industry codes, rules and regulations in each geographic area
            where the advertisements will run. All disclosures in the
            advertisements must be clear and conspicuous.
          </div>
          <br />
          <div className="px-2 lg:px-20">
            4. Review: All the advertisements are subject to the review and
            approval of VYLOO TECHNOLOGIES. VYLOO TECHNOLOGIES reserves the
            right to reject or remove any advertisement in its sole discretion
            for any reason. Further, VYLOO TECHNOLOGIES also reserves the right
            to request modifications to any advertisement, and to require
            factual substantiation for any claim made in an advertisement.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            5. Prohibited Content: The advertisements must not infringe the
            intellectual property, privacy, publicity, copyright, or other legal
            rights of any person or entity. The advertisements must not be
            false, misleading, fraudulent, defamatory, or deceptive. The
            following advertisement content is prohibited:
          </div>{' '}
          <br />
          <div className="px-2 lg:px-24">
            1. content that demeans, degrades, or shows hate toward a particular
            race, gender, culture, country, belief, or toward any member of a
            protected class;
          </div>
          <div className="px-2 lg:px-24">
            2. content depicting nudity, sexual behaviour, or obscene gestures;
            3. content depicting drug use;{' '}
          </div>
          <div className="px-2 lg:px-24">
            4. content depicting excessive violence, including the harming of
            animals; 5. shocking, sensational, or disrespectful content;
          </div>
          <div className="px-2 lg:px-24">
            6. deceptive, false or misleading content, including deceptive
            claims, offers, or business practices;{' '}
          </div>
          <div className="px-2 lg:px-24">
            7. content that directs users to phishing links, malware, or
            similarly harmful codes or sites; and
          </div>
          <div className="px-2 lg:px-24">
            8. content that deceives the Users into providing personal
            information without their knowledge, under false pretences, or to
            companies that resell, trade, or otherwise misuse that personal
            information; and{' '}
          </div>
          <div className="px-2 lg:px-24">
            9. content that violates any law for the time being in force.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            6. Prohibited Advertisements: Advertisements for the following
            products and services are prohibited:
          </div>{' '}
          <br />
          <div className="px-2 lg:px-24">
            1. adult products and services (other than contraceptives; see
            below);
          </div>
          <div className="px-2 lg:px-24">
            2. cigarettes (including e-cigarettes), cigars, smokeless tobacco,
            and other tobacco products;
          </div>
          <div className="px-2 lg:px-24">
            3. products or services that bypass copyright protection, such as
            software or cable signal descramblers;
          </div>
          <div className="px-2 lg:px-24">
            4. products or services principally dedicated to selling counterfeit
            goods or engaging in copyright piracy;{' '}
          </div>
          <div className="px-2 lg:px-24">
            5. get-rich-quick or pyramid schemes or offers or any other
            deceptive or fraudulent offers;{' '}
          </div>
          <div className="px-2 lg:px-24">
            6. illegal or recreational drugs or drug paraphernalia;
          </div>
          <div className="px-2 lg:px-24">
            7. counterfeit, fake or bootleg products, or replicas or imitations
            of designer products;{' '}
          </div>
          <div className="px-2 lg:px-24">
            8. firearms, weapons, ammunition, or accessories;{' '}
          </div>
          <div className="px-2 lg:px-24">
            9. advertisements that promote particular securities or that provide
            or allege to provide insider tips;{' '}
          </div>
          <div className="px-2 lg:px-24">
            10. any illegal conduct, product, or enterprise;{' '}
          </div>
          <div className="px-2 lg:px-24">
            11. unapproved pharmaceuticals and supplements;{' '}
          </div>
          <div className="px-2 lg:px-24">12. prescription drugs; </div>
          <div className="px-2 lg:px-24">
            13. products that have been subject to any government or regulatory
            action or warning;{' '}
          </div>
          <div className="px-2 lg:px-24">
            14. products with names that are confusingly similar to an
            unapproved pharmaceutical or supplement or controlled substance; and{' '}
          </div>
          <div className="px-2 lg:px-24">
            15. material that directly advertises products to or is intended to
            attract children under the age of 13.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            7. Prohibited Advertisements under the Drugs and Magic Act:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            1. subject to the provisions of the Drugs and Magic Act, no person
            shall take any part in the publication of any advertisement
            referring to any drug which suggest or are calculated to lead to the
            use of that drug for –
          </div>
          <br />
          <div className="px-2 lg:px-28">
            {' '}
            1. the procurement of miscarriage in women or prevention of
            conception in women; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            2. the maintenance or improvement of the capacity of human beings
            for sexual pleasure; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            3. the correction of menstrual disorder in women; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            4. the diagnosis, cure, mitigation, treatment or prevention of any
            disease, disorder or condition specified in the schedule of the
            Drugs and Magic Act, or any other disease, disorder or condition (by
            whatsoever name called) which may be specified in the rules made
            under the Drugs and Magic Act; or provided that no such rule shall
            be made except, – (i) in respect of any disease, disorder or
            condition which requires timely treatment in consultation with a
            doctor or for which there are normally no accepted remedies; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            5. prohibition of misleading advertisements relating to drugs;{' '}
          </div>
          <div className="px-2 lg:px-28">
            6. subject to the provisions of the Drugs and Magic Act, no person
            shall take any part in the publication of any advertisement relating
            to a drug if the advertisement contains any matters which:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-32">
            1. directly or indirectly gives a false impression regarding the
            true character of the drug; or
          </div>
          <div className="px-2 lg:px-32">
            2. makes a false claim for the drug; or{' '}
          </div>
          <div className="px-2 lg:px-32">
            3. is otherwise false or misleading in any material particular.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            2. It is hereby clarified that that the Third Party Advertisers will
            comply with all the provisions of the Drugs and Magic Act and the
            rules made thereunder. Further, it is agreed that the Third Party
            Advertisers shall be solely responsible for any penalty or any
            action taken by the governmental authorities for non-compliance with
            the Drugs and Magic Act and the rules made thereunder.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            8. Restricted Advertisements: Advertisements in the following
            categories are restricted and require approval on a case-by-case
            basis:{' '}
          </div>
          <div className="px-2 lg:px-24">
            1. advertisements that promote or reference alcohol;{' '}
          </div>
          <div className="px-2 lg:px-24">
            2. advertisements for online dating services;{' '}
          </div>
          <div className="px-2 lg:px-24">
            3. advertisements for gambling and games of skill;{' '}
          </div>
          <div className="px-2 lg:px-24">4. advertisements for lotteries; </div>
          <div className="px-2 lg:px-24">
            5. advertisements for financial services;{' '}
          </div>
          <div className="px-2 lg:px-24">
            6. advertisements for contraceptives;{' '}
          </div>
          <div className="px-2 lg:px-24">
            7. advertisements for online pharmacies or pharmaceuticals; and{' '}
          </div>
          <div className="px-2 lg:px-24">8. political advertisements. </div>
          <br />
          <div className="px-2 lg:px-20">9. Testimonials & Endorsements: </div>
          <br />
          <div className="px-2 lg:px-24">
            1. any testimonials and endorsements contained in advertisements
            must comply with all applicable law(s), industry codes, rules, and
            regulations. For example, a clear and conspicuous disclaimer is
            required if an endorser's results were atypical or if the endorser
            was paid;{' '}
          </div>
          <div className="px-2 lg:px-24">
            2. VYLOO TECHNOLOGIES recognizes and maintains a distinct separation
            between advertising and sponsored content and editorial content. All
            advertising or sponsored content on the Website of the Company will
            be clearly and unambiguously identified; and{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            3. a click on an advertisement may only link the User to the website
            of the Third Party Advertiser(s).{' '}
          </div>
          <br />
          <div>3. Editorial Policy for the Website – </div>
          <br />
          <div className="px-2 lg:px-20">
            1. As part of the Services, VYLOO TECHNOLOGIES provides VYLOO
            TECHNOLOGIES Content on the Website targeted at general public for
            informational purposes only and does not constitute professional
            medical advice, diagnosis, treatment or recommendations of any kind.
            VYLOO TECHNOLOGIES Content is subject to the following rules/
            information:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            1. VYLOO TECHNOLOGIES Content is original and is relevant to the
            general public;
          </div>
          <div className="px-2 lg:px-24">
            2. topics for VYLOO TECHNOLOGIES Content are selected by Our board
            of qualified experts consisting of certified medical experts,
            pharmacist and medical professionals;{' '}
          </div>
          <div className="px-2 lg:px-24">
            3. topics for VYLOO TECHNOLOGIES Content are chosen on the basis of
            current health news, drug alerts, new drug launches, latest medical
            findings published in peer-reviewed medical journals, such as ‘The
            Journal of the American Medical Association’, ‘The New England
            Journal of Medicine’, ‘The Lancet’, ‘Pediatrics’, ‘Diabetes Care’,
            and many others;{' '}
          </div>
          <div className="px-2 lg:px-24">
            4. editorial board (as mentioned below) takes into account the
            latest trending health and wellness topics like dengue, swine flu,
            seasonal allergies, new vaccines, public awareness trends like
            breast cancer awareness month," and ‘Healthy Heart Month’; as well
            as emerging health and nutrition trends like health benefits quinoa,
            use of BGR 34 for managing diabetes, alternative medicine like
            ayurveda, homeopathy and much more;{' '}
          </div>
          <div className="px-2 lg:px-24">
            5. VYLOO TECHNOLOGIES maintains principles of fairness, accuracy,
            objectivity, and responsible, independent reporting;{' '}
          </div>
          <div className="px-2 lg:px-24">
            6. the member of VYLOO TECHNOLOGIES has to fully disclose any
            potential conflict of interest with any of the Third Party Service
            Providers;{' '}
          </div>
          <div className="px-2 lg:px-24">
            7. VYLOO TECHNOLOGIES’s editorial staff holds the responsibility of
            providing objective, accurate, and balanced accounts of events and
            issues; and{' '}
          </div>
          <div className="px-2 lg:px-28">
            8. VYLOO TECHNOLOGIES’s editorial board constitutes of:{' '}
          </div>
          <div className="px-2 lg:px-28">1. Dr. Varun Gupta MBBS, MD </div>
          <div className="px-2 lg:px-28">2. Dr. Anuj Saini MBBS, MMST </div>
          <div className="px-2 lg:px-28">3. Dr. Sakshi Jain BDS, MS </div>
          <div className="px-2 lg:px-28">4. Dr. Swati Mishra BDS </div>
          <div className="px-2 lg:px-28">5. Dr. Sakshi Sharma BDS </div>
          <div className="px-2 lg:px-28">6. Dr. Deepak Soni BAMS </div>
          <br />
          <div>
            4. Terms for use of the Online Doctor Consultancy Services:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            1. VYLOO TECHNOLOGIES is an online health platform that provides a
            variety of online and online-linked health products and services to
            the Users for health related information and resources. Whenever We
            use the words "Your physician" or "Your doctor" or "healthcare
            provider" or similar words on the Website, including in these Terms
            of Use, We mean Your personal doctor with whom You have an actual,
            mutually acknowledged, doctor-patient relationship. VYLOO
            TECHNOLOGIES’s Medical Experts are not "Your" physician or
            healthcare provider.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            2. NO DOCTOR-PATIENT RELATIONSHIP: VYLOO TECHNOLOGIES does not
            replace Your relationship with physician or healthcare provider. The
            information interpreted SHOULD NOT be relied upon as a substitute
            for sound professional medical advice, evaluation or care from Your
            physician or other qualified healthcare provider.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            3. You acknowledge that the Medical Experts empanelled with Us are
            independent contractors and thereby VYLOO TECHNOLOGIES has an
            independent contractor relationship with such Medical Experts and
            therefore in no event VYLOO TECHNOLOGIES will be directly or
            vicariously liable for any advice or medical consultancy or any loss
            arising therefrom that the Medical Experts may provide to You or You
            may avail as part of the Services.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            4. You acknowledge that the e-prescription which may be issued by
            the medical expert(s), in certain events, is not be a valid
            prescription under applicable law(s) of India and may not be used
            for dispensation of medicines by any pharmacist including the Third
            Party Pharmacies. You further agree and acknowledge that if You
            request us to process the e-prescription or any form of prescription
            (whether original or scanned copy of the original prescription) for
            facilitation of medicine orders, then we will only act as an
            aggregator and assume no responsibility and/ or liability in
            relation to the dispensation of the medicines, which shall at all
            times be at your sole risk and the sole responsibility of the Third
            Party Pharmacies supplying the medicines to you.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            5. You acknowledge that although some of the content, text, data,
            graphics, images, information, suggestions, guidance, and other
            material (collectively, “Information”) that is provided to You on
            the Website (including Information provided in direct response to
            Your questions or postings) may be provided by individuals in the
            medical profession, the provision of such Information does not
            create a doctor/medical professional-patient relationship, but is
            provided to inform You on various medical conditions, medical
            diagnosis and treatment and it does not constitute a direct medical
            diagnosis, treatment or prescription. Everything on the Website
            should be used for information purposes only.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            6. VYLOO TECHNOLOGIES is designed to support the health decisions
            and choices that You make. These decisions and choices are Yours,
            and We believe that You, in connection with the advice You receive
            from Your doctor or other professional healthcare provider, are the
            best decision maker about Your health. We cannot make decisions for
            you. However, what We can do is help You find good health
            information and connect with doctors for in-person information. On
            VYLOO TECHNOLOGIES You can ask and find informational questions and
            related educational answers by Medical Experts. The Website is not a
            place for the practice of medicine, but Medical Experts on the
            Website can be a resource for reliable, relevant general health
            information.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            7. Even if Your real life doctor is on VYLOO TECHNOLOGIES, personal
            medical advice, treatment or diagnosis are not permitted through the
            Website, and by using the Website You agree not to solicit these or
            use any information as if it were personal advice, treatment, or
            diagnosis. Whenever You want personal medical advice, treatment, or
            diagnosis, You should contact Your physician or professional
            healthcare provider and see them in person.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            8. We do not recommend or endorse any specific Medical Expert(s),
            tests, products, procedures, opinions, or other information that may
            be mentioned on the Website. Reliance on any information provided on
            the Website is solely at Your own risk. In case of any medical
            emergency, kindly contact Your nearest doctor/hospital or any
            related helpline.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            9. The Services are not for use in medical emergencies or for
            critical health situations requiring prompt medical attention. The
            Services are not intended to be real-time and may not be the best
            solution when a face-to-face consultation is a must and therefore We
            strongly discourage any delay in seeking advice from Your doctor on
            account of something that You may have heard/viewed on the Website.
            You take full responsibility for ensuring that the information
            submitted is accurate and VYLOO TECHNOLOGIES shall not make any
            effort to validate any information provided by You for using the
            Services with respect to content, correctness or usability. We, with
            an intention to provide the best services possible could ask You to
            share more information as and when needed.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            10. The opinions, statements, answers and tele-consultations
            (collectively “Consultation”) provided by the Medical Experts
            through the Website are solely the individual and independent
            opinions and statements of such Medical Experts and do not reflect
            the opinions of VYLOO TECHNOLOGIES, its affiliates or any other
            organizations or institutions to which such Medical Expert or such
            specialist or professional is affiliated or provides services. VYLOO
            TECHNOLOGIES does not recommend or endorse any specific tests,
            physicians, products, procedures, opinions, or other information
            that may be mentioned on the Website or by a licensee of VYLOO
            TECHNOLOGIES.
          </div>{' '}
          <br />
          <div className="px-2 lg:px-12">
            11. The inclusion of professionals, specialists and/ or Medical
            Experts on the Website or in any professional directory on the
            Website does not imply recommendation or endorsement of such
            specialists and/ or Medical Experts nor is such information intended
            as a tool for verifying the credentials, qualifications, or
            abilities of any specialists and/ or Medical Experts contained
            therein. Such information is provided on an ‘as-is’ basis and VYLOO
            TECHNOLOGIES disclaims all warranties, either express or implied,
            including but not limited to the implied warranties of
            merchantability and fitness for particular purpose.
          </div>{' '}
          <br />
          <div className="px-2 lg:px-12">
            12. VYLOO TECHNOLOGIES (the owners and the employee staff of VYLOO
            TECHNOLOGIES), Medical Experts and third-party professionals who
            offer the Services through the Website accept no responsibility for
            any medical, legal or financial events or outcomes related to the
            Services availed through the use of the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            13. VYLOO TECHNOLOGIES makes no warranty that the Services will meet
            Your requirements, or that the Service(s) will be uninterrupted,
            timely, secure, or error free. This includes loss of data or any
            service interruption caused by VYLOO TECHNOLOGIES employees. VYLOO
            TECHNOLOGIES is not responsible for transmission errors, corruption
            of data.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            14. VYLOO TECHNOLOGIES is for personal use and the Services are for
            individuals to use for supporting their personal health decisions.
            You may use the Website for personal, but not for commercial,
            purposes.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            15. The Website may not be used for illegal purposes. The
            Information and Services may not be used for any illegal purpose.
            You may not access our networks, computers, or the Information and
            Services in any manner that could damage, disable, overburden, or
            impair them, or interfere with any other person's use and enjoyment.
            You may not attempt to gain unauthorized access to any Information
            or Services, other accounts, computer systems, or networks connected
            with the Website, the Information, or Services. You may not use any
            automated means (such as a scraper) to access the Website, the
            Information, or Services for any purpose. Such unauthorized access
            includes, but is not limited to, using another person’s login
            credentials to access his or her VYLOO TECHNOLOGIES profile/
            account. Any attempt by any individual or entity to solicit login
            information of any other user or Medical Expert or to access any
            such account is an express and direct violation of these Terms of
            Use and of applicable law(s), including relevant privacy and
            security laws and laws prohibiting unfair or unethical business
            practices.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            16. Your right to use the Services is not transferable.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            17. Notwithstanding anything to the contrary contained herein, You
            alone shall be liable for Your dealings and interaction with
            patients or Medical Experts (as the case may be) contacted or
            managed through the Website and VYLOO TECHNOLOGIES shall have no
            liability or responsibility in this regard. VYLOO TECHNOLOGIES does
            not guarantee or make any representation with respect to the
            correctness, completeness or accuracy of the Information or detail
            provided by such client, patient, User, Medical Experts or any third
            party through the Website. The Services should not be used for
            emergency appointment purposes.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            18. The exchanges between the Medical Experts and the patient
            through the chat window or over telephone (as the case maybe) and
            the e-prescription would be accessible to VYLOO TECHNOLOGIES for the
            purposes of monitoring the quality of the consultation.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            19. VYLOO TECHNOLOGIES may, at its sole discretion, suspend User’s
            or Medical Expert’s ability to use or access the Website at any time
            while VYLOO TECHNOLOGIES investigates complaints or alleged
            violations of these Terms of Use, or for any other reason. VYLOO
            TECHNOLOGIES has the right to edit profiles of Medical Experts to
            make them more suitable for patient/ Users searches on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            20. The Services should not be depended upon and should not be
            treated as a replacement for obtaining consultation for diseases as
            the consultation provided through the Website is generic in the
            approach and shall not and cannot act as a substitute for physical
            consultation with a doctor. Also the Consultations provided through
            the Website are not diagnostic in nature.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            21. Risks of using VYLOO TECHNOLOGIES’s Services{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. As with any medical procedure, there are potential risks
            associated with using the Services. By using the Services, You agree
            to abide by these Terms of Use, Privacy Policy and risks described
            below. These risks include, but may not be limited to:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            1. While the Website is an honest attempt to provide access to the
            best possible medical information to the Users, the Medical Experts
            will not be examining You physically. The Medical Experts may not
            have access to all or some of Your medical history that might be
            critical to consult You. The Medical Experts will not have the
            benefit of information that would be obtained by examining You in
            person, observing Your physical condition and by going through Your
            medical records. This means that the Services provided is different
            from the diagnostic and treatment services typically decided by a
            physician. Therefore, the Medical Experts may not be aware of facts
            or information that would affect his or her opinion of Your
            diagnosis. To reduce the risk of this limitation, VYLOO TECHNOLOGIES
            strongly encourages You to be in touch with an on-ground physician
            and share the VYLOO TECHNOLOGIES’s opinion with him/her.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            2. By requesting a medical opinion through the Website, You
            acknowledge and agree that:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            1. the advice/information/opinion on diagnosis You may receive could
            be limited and provisional;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            2. the medical opinion is not intended to replace a face-to-face
            visit with a physician and it does replace an actual doctor-patient
            relationship;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            3. in case of a second opinion where there is a difference of
            opinion among Our Medical Experts and Your physician, You would bear
            the responsibility to decide on online or offline consultation, or
            procedure, and/or treatment;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            4. the Medical Expert is reliant on information provided by You and
            hence any information demonstrated to have been falsified,
            misleading or incomplete will immediately render the opinion and all
            details therein null and void;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            5. in some cases, the Medical Expert may determine that the
            transmitted information is of inadequate quality and may ask for
            more information, without which he/she may refuse to answer the
            query;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            6. in rare cases, the Medical Experts may feel that the query may
            not be answerable without physically examining the patient/ Users
            and the Consultation may be refused forthwith;{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            7. in very rare instances, security protocols could fail, causing a
            breach of privacy of personal medical information; and 8. delays in
            medical evaluation and answers could occur due to deficiencies or
            failures of the service as per those mentioned in these Terms of
            Use.{' '}
          </div>
          <br />
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            {' '}
            Other terms
          </h1>
          <hr className="w-auto py-2" />
          <div>
            1. Your Profile, Collection, Use, Storage and Transfer of Personal
            Information:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            1. Your VYLOO TECHNOLOGIES profile is created to store record of
            Your Consultations and Your personal health information online,
            including history, health conditions, allergies and medications.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            2. Any information provided as part of a web Consultation or
            obtained from use of the Services by You becomes part of Your VYLOO
            TECHNOLOGIES record. You agree to provide accurate information to
            help Us serve You best to Our knowledge, to periodically review such
            information and to update such information as and when necessary.
            VYLOO TECHNOLOGIES reserves the right to maintain, delete or destroy
            all communications and materials posted or uploaded to the Website
            according to its internal record retention and/or destruction
            policies. You might be contacted via email to review the information
            provided by You for VYLOO TECHNOLOGIES’s record or for the Services.
            Please make sure You provide a valid email-id and You update it as
            and when needed.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            3. For additional information regarding use of information about
            You, please refer to the Privacy Policy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            4. The terms “personal information” and “sensitive personal data or
            information” are defined under the Information Technology
            (Reasonable Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011 (the “SPI Rules”), and are reproduced in
            the Privacy Policy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">1. The Privacy Policy sets out: </div>
          <br />
          <div className="px-2 lg:px-24">
            1. the type of information collected from Users, including sensitive
            personal data or information;{' '}
          </div>
          <div className="px-2 lg:px-24">
            2. the purpose, means and modes of usage of such information; and{' '}
          </div>
          <div className="px-2 lg:px-24">
            3. how and to whom VYLOO TECHNOLOGIES will disclose such
            information.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. The Users are expected to read and understand the Privacy Policy,
            so as to ensure that he or she has the knowledge of:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            1. the fact that the information is being collected;{' '}
          </div>
          <div className="px-2 lg:px-24">
            2. the purpose for which the information is being collected;{' '}
          </div>
          <div className="px-2 lg:px-24">
            3. the intended recipients of the information;{' '}
          </div>
          <div className="px-2 lg:px-24">
            4. the name and address of the agency that is collecting the
            information and the agency that will retain the information; and{' '}
          </div>
          <div className="px-2 lg:px-24">
            5. the various rights available to such Users in respect of such
            information.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            5. VYLOO TECHNOLOGIES shall not be responsible in any manner for the
            authenticity of the personal information or sensitive personal data
            or information supplied by the Users to VYLOO TECHNOLOGIES or any
            other person acting on behalf of VYLOO TECHNOLOGIES.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            6. The use of the Website involves every Users’ registration
            information and browsing history being stored and submitted to the
            appropriate authorities. The consent and procedure for such
            collection and submission is provided in the Privacy Policy. The
            other information collected by VYLOO TECHNOLOGIES from Users as part
            of the registration process is described in the Privacy Policy. The
            consent and revocation procedures in relation to the same are set
            out in the Privacy Policy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            7. The Users are responsible for maintaining the confidentiality of
            the Users’ account access information and password. The Users shall
            be responsible for all uses of the Users’ account and password,
            whether or not authorized by the Users. The Users shall immediately
            notify VYLOO TECHNOLOGIES of any actual or suspected unauthorized
            use of the Users’ account or password.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            8. If a User provides any information that is untrue, inaccurate,
            not current or incomplete (or becomes untrue, inaccurate, not
            current or incomplete), or VYLOO TECHNOLOGIES has reasonable grounds
            to suspect that such information is untrue, inaccurate, not current
            or incomplete, VYLOO TECHNOLOGIES shall have the right to suspend or
            terminate such account at its sole discretion.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            9. VYLOO TECHNOLOGIES may disclose or transfer User Information (as
            defined in the Privacy Policy) to its affiliates in other countries,
            and You hereby consent to such transfer. The SPI Rules only permit
            VYLOO TECHNOLOGIES to transfer sensitive personal data or
            information including any information, to any other body corporate
            or a person in India, or located in any other country, that ensures
            the same level of data protection that is adhered to by VYLOO
            TECHNOLOGIES as provided for under the SPI Rules, only if such
            transfer is necessary for the performance of the lawful contract
            between VYLOO TECHNOLOGIES or any person on its behalf and the user
            or where the User has consented to data transfer.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            10. By accepting these Terms of Use and by registering on the
            Website, You consent to be contacted by Us and/or by our third party
            service providers.You further consent to receive Calls, emails and
            messages (SMS) notifications and information from Us and from Third
            Party Service Providers including for promotions, discount and/or
            other service delivery related issues.{' '}
          </div>
          <br />
          <div>2. Payment, Fees and Taxes: </div>
          <br />
          <div className="px-2 lg:px-12">
            1. Registration on the Website and the access to the information
            provided on the Website is free. VYLOO TECHNOLOGIES does not charge
            any fee for accessing, browsing and buying through the Website. You
            agree to make all payments directly to the respective Third Party
            Pharmacies for purchase of Pharmaceutical Goods and Services from
            such Third Party Pharmacies and to the Company in case of purchase
            of Pharmaceutical Goods and Services directly from the Company. The
            Third Party Pharmacies may choose to either personally collect such
            payment from You or may use the services of collection agents duly
            appointed in this regard. You agree and acknowledge that You shall
            not hold VYLOO TECHNOLOGIES responsible for any loss or damage
            caused to You during the process, due to any acts or omission on the
            part of third parties viz. the Third Party Pharmacies or the
            collection agents or for any actions/ omissions which are beyond the
            control of VYLOO TECHNOLOGIES.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            2. In relation to the diagnostic services being availed from the
            Website, Third Party Labs agree to pay all package fees, consulting
            fees and other fees applicable to the Third Party Labs use of such
            Services and the Third Party Labs shall not circumvent the fee
            structure. The fee is dependent on the package that the Third Party
            Labs purchase and not on actual usage of the Services. In relation
            to the Users using the diagnostic Services, the Users agree to make
            all payments directly to the respective Third Party Labs for use of
            the diagnostic Services from the Website. You agree and acknowledge
            that You shall not hold VYLOO TECHNOLOGIES responsible for any loss
            or damage caused to You during the process, due to any acts or
            omission on the part of the Third Party Labs’ any actions/ omissions
            which are beyond the control of VYLOO TECHNOLOGIES.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            3. Each User / Third Party Service Providers are solely responsible
            for payment of all taxes, legal compliances, statutory registrations
            and reporting. VYLOO TECHNOLOGIES is in no way responsible for any
            of the taxes except for its own income tax.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            4. The subscription fees for the Services, if any charged by VYLOO
            TECHNOLOGIES, could be paid online through the facility made on the
            Website. Third parties support and services are required to process
            online fee payment. VYLOO TECHNOLOGIES is not responsible for any
            loss or damage caused to User/ Third Party Service Providers during
            this process as these third parties are beyond the control of VYLOO
            TECHNOLOGIES. The fees could also be paid offline and be either
            collected personally from the User/ Third Party Service Providers or
            required to be mailed to VYLOO TECHNOLOGIES at the following address
            of its Corporate office at VYLOO TECHNOLOGIES PRIVATE LIMITED at
            10251, Gali Mill Wali, Library Road, Azad Market, Delhi,110006.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            5. All fees are exclusive of applicable taxes.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            6. VYLOO TECHNOLOGIES reserves the right to modify the fee structure
            by providing on the Website which shall be considered as valid and
            agreed communication.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            7. In order to process the payments, VYLOO TECHNOLOGIES might
            require details of User’s/ Third Party Service Providers’ bank
            account, credit card number etc. Please check Our Privacy Policy on
            how VYLOO TECHNOLOGIES uses the confidential information provided by
            Users.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            8. Available payment methods: Wallets: Paytm, AmazonPay, Mobikwik,
            AirtelMoney, Freecharge, OlaMoney, JIO Money, PhonePe, MPESA UPI –
            BHIM and GooglePay. All debit and credit cards. Cash on delivery for
            offline payments.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            9. Applicable payment charges: No charges levied.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            10. Details, including contact information of all payment service
            providers: <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Paytm (https://paytm.com/care/ticket),{' '}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;AmazonPay
            (https://www.amazonpay.in/contact), <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Mobikwik
            (https://blog.mobikwik.com/contactus/), <br />
            &nbsp;&nbsp;&nbsp;&nbsp;AirtelMoney
            (https://www.airtel.in/personal/money/contact-us),
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;Freecharge
            (https://www.freecharge.in/contactus),
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;OlaMoney
            (https://www.olamoney.com/support/index.html), <br />
            &nbsp;&nbsp;&nbsp;&nbsp;JIO Money
            (https://www.jiomoney.com/contactus.html), <br />
            &nbsp;&nbsp;&nbsp;&nbsp;PhonePe
            (https://www.phonepe.com/contact-us/),
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;MPESA UPI – BHIM
            (https://www.bhimupi.org.in/get-touch)
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;and GooglePay
            (https://support.google.com/pay/india/gethelp).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            11. Security details in relation to payment methods: API integrated.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">12. Charge-back options: No. </div>
          <br />
          <div>3. Return, Refund, Cancellation and Shipping charges: </div>
          <br />
          <div className="px-2 lg:px-12">
            We offer return and refund on the products and Services ordered by
            You on the Website which are subject to further terms and conditions
            as detailed in the return, refund, cancellation and shipping charges
            policy (“Return and Refund Policy”). The Return and Refund Policy
            forms an integral part of these Terms of Use and the Users are
            requested to carefully read the same.
          </div>
          <br />
          <div>
            4. Covenants: (Covenanters for the purposes of these Terms of Use
            shall include the Users and the Third Party Service Providers)
          </div>
          <br />
          <div className="px-2 lg:px-12">
            1. Each Covenanter undertakes that it shall not do any act or post,
            display, upload, modify, publish, transmit, update or share any
            information that -{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. belongs to another person and to which the such Covenanter does
            not have any right;{' '}
          </div>
          <div className="px-2 lg:px-20">
            2. is grossly harmful, insulting or harassing on the basis of
            gender, blasphemous, defamatory, obscene, pornographic, paedophilic,
            libellous, invasive of another's privacy including bodily privacy,
            hateful, racially or ethnically objectionable, disparaging, relating
            or encouraging money laundering or gambling, or otherwise
            inconsistent with or contrary to the laws in force;{' '}
          </div>
          <div className="px-2 lg:px-20">
            3. infringes any patent, trademark, copyright or other proprietary
            rights;{' '}
          </div>
          <div className="px-2 lg:px-20">
            4. violates any law for the time being in force;{' '}
          </div>
          <div className="px-2 lg:px-20">5. impersonates another person; </div>
          <div className="px-2 lg:px-20">6. is harmful to child; </div>
          <div className="px-2 lg:px-20">
            7. deceives or misleads the addressee about the origin of such
            messages or knowingly and intentionally communicates any information
            that is patently false or misleading in nature but may reasonably be
            perceived as a fact;{' '}
          </div>
          <div className="px-2 lg:px-20">
            8. contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </div>
          <div className="px-2 lg:px-20">
            9. is prohibited under applicable law(s) for the time being in force
            including Drugs Act read with the Drugs Rules, the Drugs and Magic
            Act, the Indian Penal Code, 1860, as amended from time to time and
            rules made there under;{' '}
          </div>
          <div className="px-2 lg:px-20">
            10. is patently false and untrue, and is written or published in any
            form, with the intent to mislead or harass a person, entity or
            agency for financial gain or to cause any injury to any person; and{' '}
          </div>
          <div className="px-2 lg:px-20">
            11. threatens the unity, integrity, defense, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">2. You are also prohibited from: </div>
          <br />
          <div className="px-2 lg:px-20">
            1. violating or attempting to violate the integrity or security of
            the Website or any VYLOO TECHNOLOGIES Content;{' '}
          </div>
          <div className="px-2 lg:px-20">
            2. transmitting any information (including job posts, messages and
            hyperlinks) on or through the Website that is disruptive or
            competitive to the provision of Services by VYLOO TECHNOLOGIES;{' '}
          </div>
          <div className="px-2 lg:px-20">
            3. intentionally submitting on the Website any incomplete, false or
            inaccurate information;{' '}
          </div>
          <div className="px-2 lg:px-20">
            4. making any unsolicited communications to other Covenanters;{' '}
          </div>
          <div className="px-2 lg:px-20">
            5. using any engine, software, tool, agent or other device or
            mechanism (such as spiders, robots, avatars or intelligent agents)
            to navigate or search the Website;{' '}
          </div>
          <div className="px-2 lg:px-20">
            6. attempting to decipher, decompile, disassemble or reverse
            engineer any part of the Website;{' '}
          </div>
          <div className="px-2 lg:px-20">
            7. copying or duplicating in any manner any of the VYLOO
            TECHNOLOGIES Content or other information available from the
            Website; and{' '}
          </div>
          <div className="px-2 lg:px-20">
            8. framing or hotlinking or deeplinking any VYLOO TECHNOLOGIES
            Content.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            {' '}
            3. VYLOO TECHNOLOGIES, upon obtaining knowledge by itself or been
            brought to actual knowledge by an affected person in writing or
            through email signed with electronic signature about any such
            information as mentioned in this Clause 4, shall be entitled to
            disable such information that is in contravention of this Clause 4
            or any provisions of these Terms of Use. VYLOO TECHNOLOGIES shall be
            entitled to preserve such information and associated records for at
            least 90 (ninety) days for production to governmental authorities
            for investigation purposes.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            4. In case of non-compliance with any applicable law(s), rules or
            regulations, or these Terms of Use or the Privacy Policy by a
            Covenanter, VYLOO TECHNOLOGIES has the right to immediately
            terminate the access or usage rights of the Covenanter to the
            Services and to remove noncompliant information.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">5. Liability</div>
          <div className="px-2 lg:px-20">
            1. VYLOO TECHNOLOGIES shall not be responsible or liable in any
            manner to the Users or any Third Party Service Providers
            (collectively referred to as the “Other Parties”) for any losses,
            damage, injuries or expenses incurred by Other Parties as a result
            of any disclosures made by VYLOO TECHNOLOGIES, where Other Parties
            have consented to the making of such disclosures by VYLOO
            TECHNOLOGIES. If the Other Parties had revoked such consent under
            the terms of the Privacy Policy, then VYLOO TECHNOLOGIES shall not
            be responsible or liable in any manner to the Other Parties for any
            losses, damage, injuries or expenses incurred by the Other Parties
            as a result of any disclosures made by VYLOO TECHNOLOGIES prior to
            its actual receipt of such revocation.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. The Other Parties shall not hold VYLOO TECHNOLOGIES responsible
            or liable in any way for any disclosures by VYLOO TECHNOLOGIES under
            Regulation 6 of the SPI Rules.
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. The Services provided by VYLOO TECHNOLOGIES or any of its
            licensors or providers or Third Party Service Providers are provided
            ‘as is’, as available, and without any warranties or conditions
            (express or implied, including the implied warranties of
            merchantability, accuracy, fitness for a particular purpose, title
            and non-infringement, arising by statute or otherwise in law or from
            a course of dealing or usage or trade). VYLOO TECHNOLOGIES does not
            provide or make any representations, warranties or guarantees,
            express or implied about the Website or the Services. VYLOO
            TECHNOLOGIES does not verify any content or information provided by
            the Other Parties on the Website and to the fullest extent permitted
            by applicable law(s), disclaims all liability arising out of the
            Other Parties’ use or reliance upon the Website, the Services, the
            VYLOO TECHNOLOGIES Content, Third Party Contents, representations
            and warranties made by the Other Parties on the Website or any loss
            arising out of the manner in which the Services have been rendered.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            4. The Website may be linked to the website of third parties,
            affiliates and business partners. VYLOO TECHNOLOGIES has no control
            over, and not liable or responsible for content, accuracy, validity,
            reliability, quality of such websites or made available by/through
            the Website. Inclusion of any link on the Website does not imply
            that VYLOO TECHNOLOGIES endorses the linked website. Other Parties
            may use the links and these services at their own risk.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            5. VYLOO TECHNOLOGIES shall not be responsible for the
            mishaps/missed services due to no service/no show from the Other
            Parties; VYLOO TECHNOLOGIES shall not be responsible for any error
            in any of the services being provided by the Third Party Service
            Providers.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            6. Users accept and acknowledge that VYLOO TECHNOLOGIES does not
            provide any representation or give any guarantee or warranty
            (whether express or implied, or whether arising by virtue of a
            statue or otherwise in law or from a course of dealing or usage or
            trade) in relation to the goods/ products and services made
            available on its Website by Third Party Service Providers, including
            any guarantee or warrantee that such goods/ products (i) are
            merchantable; (ii) fit for the purpose of which they are to be (or
            have been) purchased;(iii) have accurate description; (iv) do not
            cause any infringement; and (v) that the Third Party Service
            Providers have legal title over the goods/products being offered for
            sale by them on the Website. VYLOO TECHNOLOGIES also does not
            provide any representation or give any guarantee or warranty
            (whether express or implied) about the Website or any of the
            Services offered or services offered or provided by the Third Party
            Service Providers.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            7. The Other Parties further accept and acknowledge that VYLOO
            TECHNOLOGIES does not verify any content or information provided by
            either the Users or the Third Party Services/ or obtained from the
            Users or the Third Party Service Providers, and to fullest extent
            permitted by applicable law(s), disclaims all liability arising out
            of the Other Parties’ use or reliance upon the Website, the
            Services, the VYLOO TECHNOLOGIES Content, Third Party Content,
            representations and warranties made by the Other Parties on the
            Website or any opinion or suggestion given or expressed by VYLOO
            TECHNOLOGIES or any Third Party Service Providers in relation to any
            Services provided by VYLOO TECHNOLOGIES.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            8. VYLOO TECHNOLOGIES assumes no responsibility, and shall not be
            liable for, any damages to, or viruses that may infect Other
            Parties’ equipment on account of the Other Parties’ access to, use
            of, or browsing the Website or the downloading of any material,
            data, text, images, video content, or audio content from the
            Website. If any of the Other Party is dissatisfied with the Website,
            the sole remedy of such Other Party(s) is to discontinue using the
            Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            9. The listing of Third Party Service Providers on the Website is
            based on numerous factors including Users comments and feedbacks. In
            no event shall the Protected Entities (as defined herein below) be
            liable or responsible for the listing order of Third Party Service
            Providers on the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            10. To the maximum extent permitted by applicable law(s), VYLOO
            TECHNOLOGIES, its affiliates, independent contractors, service
            providers, consultants, licensors, agents, and representatives, and
            each of their respective directors, officers or employees
            (“Protected Entities”), shall not be liable for any direct,
            indirect, special, incidental, punitive, exemplary or consequential
            damages, or any other damages of any kind, arising from, or directly
            or indirectly related to, (i) the use of, or the inability to use,
            the Website or the content, materials and functions related thereto;
            (ii) User's provision of information via the Website; even if such
            Protected Entity has been advised of the possibility of such
            damages.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            11. In no event shall the Protected Entities be liable for, or in
            connection with, (i) the provision of, or failure to provide, all or
            any products or service by a Third Party Service Provider to any
            User; or (ii) any comments or feedback given by any of the Users in
            relation to the goods or services provided by any Third Party
            Service Providers; or (ii) any content posted, transmitted,
            exchanged or received by or on behalf of any User, Third Party
            Service Providers or other person on or through the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            12. VYLOO TECHNOLOGIES disclaims any liability in relation to the
            validity of the medical advice provided by the Medical Experts and
            the validity and legality of the e-prescription for dispensation of
            medicines and conduct of diagnostic tests. All liabilities arising
            out of any wrong diagnosis of medical condition by the Medical
            Experts and/ or arising from the e-prescription will be of the
            concerned Medical Expert. Further, all liabilities arising out of
            any wrong diagnosis report by the Third Party Labs and/ or arising
            from the wrong dispensation of the Pharmaceutical Goods and Services
            will be of the concerned Third Party Labs or the Third Party
            Pharmacies as the case may be.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            13. The Users may share their previous medical history during
            interaction with the Medical Experts. The Users undertake to share
            such information at their own risk. VYLOO TECHNOLOGIES reserves the
            right to retain such information for the purpose of providing
            Services to the Users.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            14. With respect to the Consultation Services, after selection of
            the type of treatment viz. Homeopathy, Allopathy or Ayurveda along
            with the specification of the disease by the patient, VYLOO
            TECHNOLOGIES will decide the Medical Expert to whom the query should
            be directed based on the information shared by the User. However, in
            no event the Protected Entities shall be held liable for the losses
            attributable to such decision making and in no event shall the
            Protected Entities be liable for any Consultation provided and/or
            e-prescription issued by the Medical Expert by using the interface
            of online medical consultancy.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            15. The Users acknowledge that the Protected Entities merely act in
            the capacity of facilitators between the Other Parties by providing
            a platform for them to interact and transact. In no event shall the
            Protected Entities be held liable for any of the losses attributable
            to Services offered through the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            16. In no event shall the total aggregate liability of the Protected
            Entities to any Other Parties for all damages, losses, and causes of
            action (whether in contract or tort, including, but not limited to
            negligence, strict liability, product liability or otherwise)
            arising from these Terms of Use or any Other Parties’ use of the
            Website exceed an aggregate amount of INR 1000/- (Indian Rupees One
            Thousand only). VYLOO TECHNOLOGIES accepts no liability for any
            errors or omissions on behalf of the Other Parties.
          </div>
          <br />
          <div className="px-2 lg:px-20">
            17. In no event shall the Protected Entities be liable for failure
            on the part of the Users or Third Party Service Providers to provide
            agreed services or to make himself/herself available at the
            appointed time, cancellation or rescheduling of appointments. In no
            event shall the Protected Entities be liable for any comments or
            feedback given by any of the Users in relation to the services
            provided by a Third Party Service Providers.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">6. Indemnity </div>
          <div className="px-2 lg:px-20">
            The Covenanters agree to defend, indemnify and hold harmless VYLOO
            TECHNOLOGIES, the Protected Entities, independent contractors,
            service providers, consultants, licensors, agents, and
            representatives, and each of their respective directors, officers
            and employees, from and against any and all claims, losses,
            liability, damages, and/or costs (including, but not limited to,
            reasonable attorney fees and costs) arising from or related to (a)
            Covenanters access to or use of Website; (b) Covenanters violation
            of these Terms of Use or any applicable law(s); (c) Covenanters
            violation of any rights of another person/ entity, including
            infringement of their intellectual property rights; or (d)
            Covenanters conduct in connection with the Website.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">7. Modification of Website </div>
          <div className="px-2 lg:px-20">
            VYLOO TECHNOLOGIES reserves the right to modify or discontinue,
            temporarily or permanently, the Website or any features or portions
            thereof without prior notice. Other Parties agree that VYLOO
            TECHNOLOGIES will not be liable for any modification, suspension or
            discontinuance of the Website or any other part thereof.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">8. Intellectual property rights </div>
          <div className="px-2 lg:px-20">
            All the intellectual property used on the Website except those which
            have been identified as the intellectual properties of the Other
            Parties shall remain the exclusive property of the Company. The
            Other Parties agree not to circumvent, disable or otherwise
            interfere with security related features of the Website or features
            that prevent or restrict use or copying of any materials or enforce
            limitations on use of the Website or the materials therein. The
            materials on the Website or otherwise may not be modified, copied,
            reproduced, distributed, republished, downloaded, displayed, sold,
            compiled, posted or transmitted in any form or by any means,
            including but not limited to, electronic, mechanical, photocopying,
            recording or other means.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">9. Compliance of Applicable Law </div>
          <br />
          <div className="px-2 lg:px-20">
            1. While communicating/ transacting with each other through the
            Website, the Other Parties shall at all times ensure full compliance
            with the applicable provisions of the Contract Act, IT Act, IG
            Guidelines, Drugs Act read with the Drug Rules, Drugs and Magic Act,
            The Indian Medical Council Act, 1956 read with the Indian Medical
            Council Rules, 1957, Pharmacy Act, Consumer Protection Act, 1986,
            SPI Rules, etc (“Captioned Laws”). as well as all other laws for the
            time being in force, and ensure due payment of applicable taxes.
            They must specifically ensure that they are in no way purchasing
            Pharmaceutical Good and Services or Prescription Drugs without a
            valid prescription, which are prohibited under the Drugs Act (read
            with the Drugs Rules) as well as the other applicable laws for the
            time being in force.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            2. The Users must also ensure that the prescription uploaded on the
            Website or emailed to VYLOO TECHNOLOGIES for processing the order
            for Prescription Drugs is a valid prescription duly obtained from a
            registered medical practitioner. The Users acknowledge and accept
            that they shall bear all costs/ liability/ damages, caused to the
            Third Party Service Providers or to VYLOO TECHNOLOGIES, as a result
            of any dispensation of Prescription Drugs by the Third Party Service
            Providers owing to the non-compliance by the User in this regard.
          </div>
          <br />
          <div className="px-2 lg:px-12">
            10. Termination (Parties for the Purpose of these Terms of Use shall
            collectively mean the Other Parties and VYLOO TECHNOLOGIES){' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            1. The provisions of these Terms of Use shall continue to apply
            until terminated by either of the Party as set for below:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            1. In case of Other Parties wanting to terminate these Terms of Use,
            Other Parties may do so by:{' '}
          </div>
          <div className="px-2 lg:px-28">1. not accessing the Website; or </div>
          <div className="px-2 lg:px-28">
            2. closing their accounts for all of the Services that they use.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-24">
            2. VYLOO TECHNOLOGIES reserves the right to, at any time, and with
            or without notice, terminate these Terms of Use against each of the
            Users or the Third Party Service Providers or the Other Parties as a
            whole, if there is:{' '}
          </div>
          <br />
          <div className="px-2 lg:px-28">
            1. breach any of applicable law(s), including but not limited to the
            Captioned Laws or the provisions of these Terms of Use or the terms
            of the Privacy Policy or any other terms, conditions, or policies
            that may be applicable to the Other Parties from time to time (or
            have acted in a manner that clearly shows that Other Party(s) do not
            intend to, or are unable to, comply with the same); or{' '}
          </div>
          <div className="px-2 lg:px-28">
            2. VYLOO TECHNOLOGIES is unable to verify or authenticate any
            information provided to VYLOO TECHNOLOGIES by Other Party(s); or{' '}
          </div>
          <div className="px-2 lg:px-28">
            3. VYLOO TECHNOLOGIES believes, in its sole discretion, that Other
            Party(s) actions may cause legal liability for VYLOO TECHNOLOGIES
            (or any of its affiliates, independent contractors, service
            providers, consultants, licensors, agents, and representatives) or
            are contrary to the interests of the Website; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            4. VYLOO TECHNOLOGIES is required to do so by law; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            5. if Other Party(s) fail to provide (or after providing such
            consent, later revoke) the consents necessary or desirable for VYLOO
            TECHNOLOGIES to provide the Services to the Other Party(s); or{' '}
          </div>
          <div className="px-2 lg:px-28">
            6. The provision of the Services to the Other Party(s), or to the
            general public, is in VYLOO TECHNOLOGIES’s opinion, no longer
            commercially viable; or{' '}
          </div>
          <div className="px-2 lg:px-28">
            7. VYLOO TECHNOLOGIES has elected to discontinue, with or without
            reason, access to the Website or the Services (or any part thereof).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            3. VYLOO TECHNOLOGIES may also terminate or suspend (temporarily or
            permanently) all or a portion of Other Party(s) account or access to
            the Services, with or without reason. Except as may be set forth in
            any of the terms applicable to a particular Service, termination of
            Other Party(s) account may include: (i) removal of access to all
            offerings within the Website or with respect to the Services; and
            (ii) barring Other Party(s) from further use or access of the
            Website or of any of the Services.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            4. Once terminated or suspended (temporarily or permanently), Other
            Party(s) may not continue to use the Website under the same account,
            a different account or re-register under a new account.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            5. Upon termination of these Terms of Use, VYLOO TECHNOLOGIES shall
            have no obligation to maintain or provide any of Other Party(s) data
            and may thereafter, unless legally prohibited, delete all of Other
            Party(s) data in its systems or otherwise in its possession or under
            its control, including but not limited to Other Party(s) personal
            information, log-in ID and password, order details (including any
            prescriptions uploaded) and all related information, files and
            materials associated with or inside Other Party(s) account (or any
            part thereof).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            6. VYLOO TECHNOLOGIES reserves the right, at its sole discretion, to
            pursue all of its legal remedies, including but not limited to
            deletion of the Other Party(s) content from the Website with or
            without ability to access the Website and the other Services, upon
            any breach by the Other Party(s) of these Terms of Use or if VYLOO
            TECHNOLOGIES is unable to verify or authenticate any information the
            Other Party(s) submits to VYLOO TECHNOLOGIES, or if the Other
            Party(s) fail to provide (or after providing such consent, later
            revokes) the consents necessary or desirable for VYLOO TECHNOLOGIES
            to provide the Services to the Other Party(s).{' '}
          </div>
          <br />
          <div className="px-2 lg:px-20">
            7. The right to terminate/ suspend the account is in addition to,
            and without prejudice to, VYLOO TECHNOLOGIES’s right to initiate
            action against the Other Party(s), in accordance with applicable
            law.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">11. Force Majeure </div>
          <div className="px-2 lg:px-20">
            Other Parties accept and acknowledge that VYLOO TECHNOLOGIES shall
            not be liable for any loss or damage caused to the User as a result
            of delay or default or deficiency or failure in the Services as a
            result of any natural disasters, fire, riots, civil disturbances,
            actions or decrees of governmental bodies, communication line
            failures (which are not caused due to the fault of VYLOO
            TECHNOLOGIES or the Third Party Service Providers), or any other
            delay or default or deficiency or failure which arises from causes
            beyond VYLOO TECHNOLOGIES’s reasonable control (“Force Majeure
            Event”). In the event of any Force Majeure Event arising, VYLOO
            TECHNOLOGIES, depending on whose performance has been impacted under
            the Terms of Use, shall immediately give notice to the Other
            Party(s) of the facts which constitute the Force Majeure Event.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">
            12. Governing Law and Dispute Resolution{' '}
          </div>
          <div className="px-2 lg:px-20">
            These Terms of Use and any contractual obligation between the
            Parties will be governed by the laws of India, without reference to
            the conflict of laws principles. Any legal action or proceeding
            related to Other Party(s) access to, or use of, the Website or these
            Terms of Use shall be subject to the exclusive jurisdiction of the
            courts at New Delhi. All disputes will be subject to arbitration at
            New Delhi in English by a sole arbitrator appointed by VYLOO
            TECHNOLOGIES under the Arbitration and Conciliation Act, 1996.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">13. Survival </div>
          <div className="px-2 lg:px-20">
            Even after termination, certain obligations mentioned under
            Covenants, Liability, Indemnity, Intellectual Property, Dispute
            Resolution will continue and survive termination.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">14. Severability </div>
          <div className="px-2 lg:px-20">
            If any provision of these Terms of Use is deemed invalid, unlawful,
            void or for any other reason unenforceable, then that provision
            shall be deemed severable from these Terms of Use and shall not
            affect the validity and enforceability of any of the remaining
            provisions.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">15. Waiver </div>
          <div className="px-2 lg:px-20">
            No provision of these Terms of Use shall be deemed to be waived and
            no breach excused, unless such waiver or consent shall be in writing
            and signed by VYLOO TECHNOLOGIES. Any consent by VYLOO TECHNOLOGIES
            to, or a waiver by VYLOO TECHNOLOGIES of any breach by Other
            Parties, whether expressed or implied, shall not constitute consent
            to, waiver of, or excuse for any other different or subsequent
            breach.{' '}
          </div>
          <br />
          <div className="px-2 lg:px-12">16. Headings </div>
          <div className="px-2 lg:px-20">
            The headings and subheadings herein are included for convenience and
            identification only and are not intended to describe, interpret,
            define or limit the scope, extent or intent of these Terms of Use.
          </div>
          <br />
          <div className="px-2 lg:px-20">
            Vyloo Technologies Private Limited ('' Vyloo '') ensures that the
            correct medications are processed according to the order and
            prescription. Our goal is to deliver all medicines and products in
            perfect condition, without any damage, every time a customer places
            an order. We also strongly advise checking items upon delivery.
          </div>
          <br />
          <br />
        </p>
      </div>
      <>
        <div className="px-8 lg:px-28 text-justify">
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Return, Refund and Cancellation Policy
          </h1>
          <hr className="w-auto py-2" />
          <p className="text-md text-[#1c1e21ad]">
            Vyloo Technologies Private Limited ('' Vyloo '') ensures that the
            correct medications are processed according to the order and
            prescription. Our goal is to deliver all medicines and products in
            perfect condition, without any damage, every time a customer places
            an order. We also strongly advise checking items upon delivery.{' '}
            <br />
            <br />
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Definition
            </h1>
            <hr className="w-auto py-2" />
            <strong>
              'Return' means an action of giving back the product ordered at
              Vyloo Application by the consumer. The following situations may
              arise which may cause the action of return of product:
            </strong>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1. Product(s) delivered do not match your
            order;
            <br /> &nbsp;&nbsp;&nbsp;&nbsp;2. Product(s) delivered past or near
            to its expiry date (medicines with expiry date of less than 03
            months shall be considered as near expiry);
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. Product(s) delivered were damaged in
            transit (do not to accept any product which has a tampered seal):
            <br />
            <br />
            <hr className="w-auto py-2" />
            <strong>
              <em>
                Note: If you receive a damaged product, please refuse delivery.
                If you discover any damage after opening the package, you can
                return the product for a refund. Please understand that
                replacements may not always be available for all products; in
                such cases, we will issue a refund instead.{' '}
              </em>
            </strong>
            <br />
            <br />
            <hr className="w-auto py-2" />
            In the aforesaid unlikely situations, if there is something wrong
            with the order, we'd be happy to assist and resolve your concern.
            You may raise a Return request with our customer care within 03
            (Three) days from the delivery of the product. Vyloo reserves the
            right to cancel the Return request, if the customer reaches out to
            Vyloo after 3 days of delivery. Upon receiving your Return/Refund
            request, Vyloo shall verify the authenticity and the nature of the
            request. If Vyloo finds that the request is genuine, it will
            initiate the Return and Refund process. Vyloo shall process the
            refund only once it has received the confirmation from the vendor
            concerned in respect of the contents of the product relating to that
            refund. In the event of frivolous and unjustified complaints
            regarding the quality and content of the products, Vyloo reserves
            the right to pursue necessary legal actions against you and you will
            be solely liable for all costs incurred by Vyloo in this regard.
            <br />
            The returns are subject to the below conditions:-
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;1. Any wrong ordering of product doesn’t
            qualify for Return;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;2. Batch number of the product being
            returned should match as mentioned on the invoice; <br />
            &nbsp;&nbsp;&nbsp;&nbsp;3. Return requests arising due to change in
            prescription do not qualify for Return;
            <br /> &nbsp;&nbsp;&nbsp;&nbsp;4. Product being returned should only
            be in their original manufacturer's packaging i.e. with original
            price tags, labels, bar-code & invoice;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;5. Partially consumed strips or products do
            not qualify for Return, only fully unopened strips or products can
            be returned.
            <br />
            <br />
            <hr className="w-auto py-2" />
            <br />
            Category of Non-Returnable Product: Certain categories of products
            marked as non- returnable on product page, will not qualify for the
            Return as per Vyloo Return policy. The details of the non-
            returnable products are mentioned below:
            <br />
            <br />
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Categories
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Type of Products
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Baby Care
                    </th>
                    <td class="px-6 py-4">
                      Bottle Nipples, Breast Nipple Care, Breast Pumps, Diapers,
                      Ear Syringes, Nappy, Wet Reminder, Wipes and Wipe Warmers
                    </td>
                  </tr>
                  <tr class="bg-white border-b ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Food and Nutrition
                    </th>
                    <td class="px-6 py-4">Health Drinks, Health Supplements</td>
                  </tr>
                  <tr class="bg-white ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Healthcare Devices
                    </th>
                    <td class="px-6 py-4">
                      Glucometer Lancet/Strip, Healthcare Devices and Kits,
                      Surgical, Health Monitors
                    </td>
                  </tr>
                  <tr class="bg-white ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Sexual Wellness
                    </th>
                    <td class="px-6 py-4">
                      Condoms, Fertility Kit/Supplement, Lubricants, Pregnancy
                      Kits
                    </td>
                  </tr>
                  <tr class="bg-white ">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      Temperature Controlled and Speciality Medicines
                    </th>
                    <td class="px-6 py-4">
                      Vials, Injections, Vaccines, Penfills and any other
                      Product, requiring cold storage, or medicines that fall
                      under the category of speciality medicines.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Return Process
            </h1>
            <hr className="w-auto py-2" />
            1. For Return intimation, please visit https://www.vyloo.in/contact.
            <br />
            2. Vyloo customer care team will verify the claim made by the
            customer within 72 (seventy-two) business hours from the time of
            receipt of complaint.
            <br />
            3. Once the claim is verified as genuine and reasonable, Vyloo will
            initiate the collection of product(s) to be returned.
            <br />
            4. The customer will be required to pack the product(s) in original
            manufacturer’s packaging.
            <br />
            5.Refund will be completed within 30 (thirty)days from the date of
            reverse pick up (if required).
            <br />
            <br />
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Refund Process
            </h1>
            <hr className="w-auto py-2" />
            In all the above cases, if the claim is found to be valid, Refund
            will be made as mentioned below: <br />
            1. Order placed through online medium will be refunded through fund
            transfer to customer bank account. <br />
            2. Order placed through cash on delivery will be refunded through
            fund transfer to the customer bank account.
            <br />
            <br />
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Cancellation Policy
            </h1>
            <hr className="w-auto py-2" />
            An order once placed can not be canceled after payment by the
            customer. This is made to ensure a hasslefree marketplace
            environment for delivery riders and sellers to keep operating and
            provide speedy services on the application.
            <br />
            <br />
            <h1 className="text-2xl font-black text-[#16496F] py-1">
              Attribution of Freepik Images
            </h1>
            <hr className="w-auto py-2" />
            In compliance with Freepik's licensing terms, our mobile
            applications on the Play Store and App Store utilize images and
            resources from Freepik. As required, we provide proper attribution
            for these resources in the terms and conditions of the application.
            This ensures that we adhere to Freepik's guidelines, allowing us to
            legally and ethically use their high-quality images and resources in
            our applications.
            <br />
            <br />
          </p>
        </div>
      </>
    </>
  );
}
