import { useEffect, useState } from 'react';
import axios from 'axios';

const Otcs = () => {
  const [otcs, setMedicines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [productToAdd, setProductToAdd] = useState(null);
  const [showDropdown, setShowDropdown] = useState(null); // Track which product's dropdown is open
  const [updatingProductIds, setUpdatingProductIds] = useState([]); // Track products being updated
  const [editingProduct, setEditingProduct] = useState(null);
  const [updatedProductDetails, setUpdatedProductDetails] = useState({
    displayName: '',
    mrp: '',
    packaging: '',
    packageType: '',
  });

  const [filter, setFilter] = useState('all');
  const adminRole = sessionStorage.getItem('adminRole');

  const fetchMedicines = async () => {
    setLoading(true);
    setError('');

    try {
      const token = sessionStorage.getItem('authToken');
      let url =
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllProducts';
      let params = {
        page,
        size: 12,
        productType: 'otc',
        query: searchQuery,
      };

      // Modify API URL and params based on the filter state
      if (filter === 'active') {
        url = `${url}?status=active`;
      } else if (filter === 'inactive') {
        url = `${url}?status=inactive`;
      }

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.data.success) {
        const productsData = response.data.data.products.flatMap(
          (productGroup) => productGroup.products
        );
        setMedicines(productsData);
        setTotalPages(Math.ceil(response.data.data.totalItems / 10));
      } else {
        setError('Failed to fetch otcs.');
      }
    } catch (error) {
      setError('An error occurred while fetching otcs.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMedicines();
  }, [page, searchQuery, filter]); // Include 'filter' as a dependency

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.get(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllActiveCategories',
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setCategories(response.data.data.categories);
        } else {
          setError('Failed to fetch categories.');
        }
      } catch (error) {
        setError('An error occurred while fetching categories.');
      }
    };

    fetchCategories();
  }, []);

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === 'previous' && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setPage(1);
  };

  const handleCategoryDropdown = (productId) => {
    if (showDropdown === productId) {
      setShowDropdown(null);
    } else {
      setShowDropdown(productId);
    }
  };

  const handleCategorySelect = async (categoryId) => {
    setSelectedCategoryId(categoryId);
    setSubCategories([]);

    if (categoryId) {
      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllActiveSubCategoriesOfCategory',
          {
            categoryId,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setSubCategories(response.data.data.subCategories);
        } else {
          setError('Failed to fetch subcategories.');
        }
      } catch (error) {
        setError('An error occurred while fetching subcategories.');
      }
    }
  };

  const handleSubCategorySelect = (subCategoryId, productId) => {
    setSelectedSubCategoryId(subCategoryId);
    setProductToAdd(productId);
  };

  const handleAddToSubCategory = async () => {
    if (!productToAdd || !selectedSubCategoryId) return;

    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/addProductToSubCategory',
        {
          productId: productToAdd,
          subCategoryId: selectedSubCategoryId,
          productType: 'otc',
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        alert('Product added to subcategory successfully!');
        setSelectedCategoryId(null);
        setSelectedSubCategoryId(null);
        setProductToAdd(null);
        setShowDropdown(null);
      } else {
        setError('Failed to add product to subcategory.');
      }
    } catch (error) {
      setError('An error occurred while adding product to subcategory.');
    }
  };

  const handleStatusToggle = async (productId, currentActiveStatus) => {
    const desiredFlag = !currentActiveStatus;

    setUpdatingProductIds((prev) => [...prev, productId]);

    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/toggleProductAvailability',
        {
          productId: productId,
          productType: 'otc',
          flag: desiredFlag,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        setMedicines((prevMedicines) =>
          prevMedicines.map((otc) =>
            otc._id === productId ? { ...otc, active: desiredFlag } : otc
          )
        );
      } else {
        alert('Failed to update product status.');
      }
    } catch (error) {
      alert('An error occurred while updating product status.');
    } finally {
      setUpdatingProductIds((prev) => prev.filter((id) => id !== productId));
    }
  };

  const handleEditClick = (medicine) => {
    setEditingProduct(medicine._id);
    setUpdatedProductDetails({
      displayName: medicine.displayName,
      mrp: medicine.mrp,
      packaging: medicine.packaging,
      packageType: medicine.packageType,
    });
  };

  const handleProductDetailsChange = (event) => {
    const { name, value } = event.target;
    setUpdatedProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateProduct = async () => {
    if (!editingProduct) return;

    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.post(
        `https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/${adminRole}/updateProductDetails`,
        {
          productId: editingProduct,
          productType: 'otc',
          update: updatedProductDetails,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        alert('Product details updated successfully!');
        setMedicines((prevMedicines) =>
          prevMedicines.map((medicine) =>
            medicine._id === editingProduct
              ? { ...medicine, ...updatedProductDetails }
              : medicine
          )
        );
        setEditingProduct(null);
      } else {
        setError('Failed to update product details.');
      }
    } catch (error) {
      setError('An error occurred while updating product details.');
    }
  };

  const filteredMedicines = otcs.filter((otc) => {
    if (filter === 'all') return true;
    if (filter === 'active') return otc.active;
    if (filter === 'inactive') return !otc.active;
    return true;
  });

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">OTC Medicines</h1>

      {/* Filter buttons */}
      <div className="mb-4">
        <button
          onClick={() => setFilter('all')}
          className={`px-4 py-2 rounded-md mr-2 ${
            filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-300'
          }`}
        >
          All
        </button>
        <button
          onClick={() => setFilter('active')}
          className={`px-4 py-2 rounded-md mr-2 ${
            filter === 'active' ? 'bg-blue-500 text-white' : 'bg-gray-300'
          }`}
        >
          Active
        </button>
        <button
          onClick={() => setFilter('inactive')}
          className={`px-4 py-2 rounded-md ${
            filter === 'inactive' ? 'bg-blue-500 text-white' : 'bg-gray-300'
          }`}
        >
          Inactive
        </button>
      </div>

      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          className="px-4 py-2 border rounded-md"
          placeholder="Search medicines"
        />
      </div>

      {/* Product list rendering */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredMedicines.map((medicine) => (
              <div
                key={medicine._id}
                className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between"
              >
                <div>
                  <h2 className="text-lg font-bold">{medicine.displayName}</h2>
                  <p className="text-gray-700 mt-2">
                    Packaging: {medicine.packaging}
                  </p>
                  <p className="text-gray-700 mt-2">
                    Manufacturer: {medicine.manufacturer}
                  </p>
                  <p className="text-gray-700 mt-2">
                    MRP: <strong>₹{medicine.mrp}</strong>
                  </p>
                  <div className="mt-2 flex items-center">
                    <span
                      className={`font-semibold mr-2 ${
                        medicine.active ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      Status: {medicine.active ? 'Active' : 'Inactive'}
                    </span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={medicine.active}
                        onChange={() =>
                          handleStatusToggle(medicine._id, medicine.active)
                        }
                        disabled={updatingProductIds.includes(medicine._id)}
                      />
                      <div
                        className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer
                      dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white
                      after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300
                      after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600
                      peer-checked:bg-blue-600"
                      ></div>
                    </label>
                    {updatingProductIds.includes(medicine._id) && (
                      <span className="ml-2 text-sm text-gray-500">
                        Updating...
                      </span>
                    )}
                  </div>
                </div>
                {medicine.imageUrl && (
                  <img
                    src={`https://d2l55457kk8x11.cloudfront.net/${medicine.imageUrl}`}
                    alt={medicine.displayName}
                    className="mt-4 w-24 rounded-lg mx-auto block"
                  />
                )}

                <div className="mt-4">
                  <button
                    className="bg-blue-500 text-white py-1 px-2 rounded w-full"
                    onClick={() => handleCategoryDropdown(medicine._id)}
                  >
                    Add to Subcategory
                  </button>

                  {showDropdown === medicine._id && (
                    <>
                      <select
                        className="mt-2 p-2 border border-gray-300 rounded w-full"
                        onChange={(e) => handleCategorySelect(e.target.value)}
                        value={selectedCategoryId || ''}
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) =>
                          category.categories.map((subCategory) => (
                            <option
                              key={subCategory._id}
                              value={subCategory._id}
                            >
                              {subCategory.displayName}
                            </option>
                          ))
                        )}
                      </select>

                      {subCategories.length > 0 && (
                        <select
                          className="mt-2 p-2 border border-gray-300 rounded w-full"
                          onChange={(e) =>
                            handleSubCategorySelect(
                              e.target.value,
                              medicine._id
                            )
                          }
                          value={selectedSubCategoryId || ''}
                        >
                          <option value="">Select Subcategory</option>
                          {subCategories.map((subCategory) => (
                            <option
                              key={subCategory._id}
                              value={subCategory._id}
                            >
                              {subCategory.displayName}
                            </option>
                          ))}
                        </select>
                      )}

                      <button
                        className="bg-green-500 text-white py-1 px-2 rounded mt-2 w-full"
                        onClick={handleAddToSubCategory}
                      >
                        Confirm Add
                      </button>
                    </>
                  )}
                  <br />
                  {adminRole === 'masteradmin' && (
                    <>
                      <button
                        onClick={() => handleEditClick(medicine)}
                        className="p-1 border rounded bg-yellow-500 text-white py-1 px-2 rounded w-full"
                      >
                        Edit
                      </button>
                      {editingProduct === medicine._id && (
                        <div className="mt-2">
                          <input
                            type="text"
                            name="displayName"
                            value={updatedProductDetails.displayName}
                            onChange={handleProductDetailsChange}
                            placeholder="Display Name"
                            className="p-1 w-full border rounded mb-2"
                          />
                          <input
                            type="text"
                            name="mrp"
                            value={updatedProductDetails.mrp}
                            onChange={handleProductDetailsChange}
                            placeholder="MRP"
                            className="p-1 w-full  border rounded mb-2"
                          />
                          <input
                            type="text"
                            name="packaging"
                            value={updatedProductDetails.packaging}
                            onChange={handleProductDetailsChange}
                            placeholder="Packaging"
                            className="p-1 w-full  border rounded mb-2"
                          />
                          <input
                            type="text"
                            name="packageType"
                            value={updatedProductDetails.packageType}
                            onChange={handleProductDetailsChange}
                            placeholder="Package Type"
                            className="p-1 w-full  border rounded mb-2"
                          />
                          <button
                            onClick={handleUpdateProduct}
                            className="bg-blue-500 w-full  text-white p-1 rounded"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-between">
            <button
              onClick={() => handlePageChange('previous')}
              className="px-4 py-2 bg-gray-300 rounded-md"
              disabled={page === 1}
            >
              Previous
            </button>
            <span>
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange('next')}
              className="px-4 py-2 bg-gray-300 rounded-md"
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Otcs;
