import React from 'react';
import m3 from '../assets/3.webp';
import Logo from '../assets/logo.svg';

export default function Section1() {
  return (
    <>
      <section class="bg-white">
        <div class="max-w-6xl px-6 py-10 mx-auto">
          <h1 class="mt-2 text-2xl font-black text-[#16496F] capitalize lg:text-4xl ">
            Live Order Tracking
          </h1>
          <main class="relative z-20 w-full mt-8 md:flex md:items-center xl:mt-12">
            <div class="absolute w-full bg-[#16496F] border-8 border-[#089DD5] -z-10 md:h-96 rounded-2xl"></div>
            <div class="w-full p-6 bg-[#16496F] md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
              <img
                class="h-24 w-24 md:mx-6 rounded-full object-cover md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl md:block hidden"
                src={m3}
                alt="Img"
              />
              <div class="mt-2 md:mx-6">
                <div>
                  <img src={Logo} className=" w-36" alt="Vyloo logo" />
                </div>
                <p class="py-6 text-lg font-light leading-relaxed text-white md:text-xl">
                  {' '}
                  “Our app offers live order tracking, allowing users to monitor
                  the status of their medicine delivery in real-time. Users
                  receive instant updates on their order's progress, including
                  order confirmation, estimated delivery time, and live location
                  tracking of the delivery driver. This feature ensures
                  transparency and convenience, empowering users with accurate
                  information and peace of mind regarding their medication
                  delivery”
                </p>
              </div>
            </div>
          </main>
        </div>
      </section>
    </>
  );
}
