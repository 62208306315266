import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MerchantsOnline = () => {
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    const fetchMerchants = async () => {
      const token = sessionStorage.getItem('authToken');
      try {
        const response = await axios.get(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/allAvailableMerchants',
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setMerchants(response.data.data.merchants);
        } else {
          console.error('Error fetching merchants:', response.data.message);
        }
      } catch (error) {
        console.error('API error:', error);
      }
    };

    fetchMerchants();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">
        Online Merchants
      </h1>
      {merchants.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {merchants.map((merchant) => (
            <div
              key={merchant._id}
              className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200"
            >
              <div className="p-4">
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {merchant.shopName}
                </h2>
                <p className="text-gray-700 mb-2">
                  <strong>Address:</strong> {merchant.addressLine1},{' '}
                  {merchant.addressLine2}
                </p>
                <p className="text-gray-700">
                  <strong>Owner Mobile Number:</strong>{' '}
                  {merchant.ownerMobileNumber}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">No merchants online at the moment.</p>
      )}
    </div>
  );
};

export default MerchantsOnline;
