import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllActiveCategories',
          {},
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          // Flattening the nested categories arrays into a single array
          const flattenedCategories = response.data.data.categories.flatMap(
            (categoryGroup) => categoryGroup.categories
          );
          setCategories(flattenedCategories);
        } else {
          setError('Failed to fetch categories.');
        }
      } catch (error) {
        setError('An error occurred while fetching categories.');
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/admin/subcategories/${categoryId}`);
  };

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Categories</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {categories.map((category) => (
            <div
              key={category._id}
              className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between cursor-pointer"
              onClick={() => handleCategoryClick(category._id)}
            >
              <div>
                <h2 className="text-lg font-bold">{category.displayName}</h2>
                <p className="text-gray-700 mt-2">Id : {category._id}</p>
                <p
                  className={`mt-2 font-semibold ${
                    category.status === 'active'
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                >
                  {category.status}
                </p>
              </div>
              {category.imageUrl && (
                <img
                  src={`https://d2l55457kk8x11.cloudfront.net/${category.imageUrl}`}
                  alt={category.displayName}
                  className="mt-4 w-24 rounded-lg mx-auto block"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Categories;
