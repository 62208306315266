import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DeliveryPartnersOnline = () => {
  const [deliveryPartners, setdeliveryPartners] = useState([]);

  useEffect(() => {
    const fetchMerchants = async () => {
      const token = sessionStorage.getItem('authToken');
      try {
        const response = await axios.get(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/allAvailableDeliveryPartners',
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setdeliveryPartners(response.data.data.deliveryPartners);
        } else {
          console.error(
            'Error fetching deliveryPartners:',
            response.data.message
          );
        }
      } catch (error) {
        console.error('API error:', error);
      }
    };

    fetchMerchants();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">
        Online Delivery Partners
      </h1>
      {deliveryPartners.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {deliveryPartners.map((delivery) => (
            <div
              key={delivery._id}
              className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200"
            >
              <div className="p-4">
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {delivery.name}
                </h2>
                <p className="text-gray-700">
                  <strong>Id:</strong> {delivery._id}
                </p>
                <p className="text-gray-700">
                  <strong>Mobile Number:</strong> {delivery.mobileNumber}
                </p>
                <p className="text-gray-700">
                  <strong>Pincode:</strong> {delivery.pinCode}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-600">
          No deliveryPartners online at the moment.
        </p>
      )}
    </div>
  );
};

export default DeliveryPartnersOnline;
