import React from 'react';
import fail from '../assets/fail.png';
import Logo from '../assets/Logo3.png';

export default function SuccessP() {
  return (
    <>
      <div className="mt-64 mb-64 text-center">
        <img
          className="text-center inline-block w-24"
          alt="failed_img"
          src={fail}
        />
        <br />
        <br />
        <p1 className="text-[#089DD5] font-black text-xl">
          Payment Failed
        </p1>{' '}
        <br />
        <p1 className="text-[#8891A6] font-bold">Please try again</p1>
        <br />
        <br />
        <img className="text-center inline-block w-24" alt="logo" src={Logo} />
      </div>
    </>
  );
}
