import React from 'react';
import { IoIosTimer } from 'react-icons/io';
import { MdDeliveryDining, MdOutlineSupportAgent } from 'react-icons/md';
import { FaStore } from 'react-icons/fa';
import { MdRecommend } from 'react-icons/md';
import { IoDocumentTextOutline } from 'react-icons/io5';

export default function Features() {
  return (
    <>
      <section class="bg-[#089DD5] text-white">
        <div class="container py-12 mx-auto">
          {/* <h1 className='text-4xl font-black pb-6'>Features</h1> */}
          <div class="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-6  text-center">
            <div className="container flex flex-col items-center py-8 mx-auto text-center">
              <IoIosTimer className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100">
                15 Minutes Medicine Delivery
              </h1>
            </div>

            <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center">
              <MdDeliveryDining className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100 ">
                Verified Delivery Partners
              </h1>
            </div>

            <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center">
              <FaStore className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100 ">
                Licensed Shop Owners
              </h1>
            </div>
            <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center">
              <MdOutlineSupportAgent className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100">
                24/7 Customer Support
              </h1>
            </div>

            <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center">
              <MdRecommend className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100">
                Personalized Recommendations
              </h1>
            </div>

            <div className="container flex flex-col items-center px-4 py-8 mx-auto text-center">
              <IoDocumentTextOutline className="w-16 h-16 " />

              <h1 class="mt-4 text-md font-semibold text-gray-100">
                Prescription Based Medicines
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
