import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const SubCategories = () => {
  const { categoryId } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const prodTypeRef = useRef('');

  useEffect(() => {
    const fetchSubCategories = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllActiveSubCategoriesOfCategory',
          {
            categoryId,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          prodTypeRef.current = response.data.data.productType;
          localStorage.setItem('productType', prodTypeRef.current);
          //console.log(prodType);
          setSubCategories(response.data.data.subCategories);
        } else {
          setError('Failed to fetch subcategories.');
        }
      } catch (error) {
        setError('An error occurred while fetching subcategories.');
      } finally {
        setLoading(false);
      }
    };
    fetchSubCategories();
  }, [categoryId]);

  const handleSubCategoryClick = (subCategoryId, productType) => {
    sessionStorage.setItem('productType', productType); // Store productType in localStorage
    navigate(`/subcategory/${subCategoryId}/products`);
  };

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">SubCategories</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {subCategories.map((subCategory) => (
            <div
              key={subCategory._id}
              className="bg-white shadow-md rounded-lg p-4 cursor-pointer"
              onClick={() =>
                handleSubCategoryClick(subCategory._id, subCategory.productType)
              }
            >
              <h2 className="text-lg font-bold">{subCategory.displayName}</h2>
              <p className="text-gray-700 mt-2">Id: {subCategory._id}</p>
              {subCategory.imageUrl && (
                <img
                  src={`https://d2l55457kk8x11.cloudfront.net/${subCategory.imageUrl}`}
                  alt={subCategory.displayName}
                  className="mt-4 w-24 rounded-lg"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubCategories;
