import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

function CreateAdmin({ isOpen, onClose }) {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const authToken = sessionStorage.getItem('authToken');
    try {
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/masteradmin/createAdmin',
        {
          name,
          password,
          mobileNumber: Number(mobileNumber),
          email,
          department,
        },
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Admin created successfully!');
        setName('');
        setPassword('');
        setMobileNumber('');
        setEmail('');
        setDepartment('');

        onClose(); // Close the modal after successful creation
      } else {
        toast.error(error.response ? error.response.data : error.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      // console.error(
      //   'Error:',
      //   error.response ? error.response.data : error.message
      // );
      // setError(
      //   'An error occurred while trying to create admin. Please try again.'
      // );
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          Create Admin
        </h2>
        <form onSubmit={handleCreateAdmin}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Name
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 text-sm text-gray-700 border rounded focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Password
            </label>
            <input
              type="password"
              className="w-full px-3 py-2 text-sm text-gray-700 border rounded focus:outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Mobile Number
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 text-sm text-gray-700 border rounded focus:outline-none"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Email
            </label>
            <input
              type="email"
              className="w-full px-3 py-2 text-sm text-gray-700 border rounded focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Department
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 text-sm text-gray-700 border rounded focus:outline-none"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-600 border border-gray-400 rounded hover:bg-gray-100 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none ${loading && 'opacity-50 cursor-not-allowed'}`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateAdmin;
