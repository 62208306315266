import React from 'react';
import Navbar from '../components/navbar';

export default function Privacy() {
  return (
    <>
      <Navbar />
      <div className="px-8 lg:px-28 text-justify">
        <p className="py-12 text-md text-[#1c1e21ad]">
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Privacy Policy
          </h1>
          <hr className="w-auto py-2" />
          Vyloo Technologies Private Limited ("Vyloo" or "we") takes the privacy
          of your information seriously. This privacy notice ("Privacy Notice")
          describes the types of personal information we collect from you
          through our website (including sub-domains and microsites) and mobile
          applications. It also describes the purposes for which we collect that
          personal information, the other parties with whom we may share it and
          the measures we take to protect the security of your data. It also
          tells you about your rights and choices with respect to your personal
          information, and how you can contact us about our privacy practices.
          You are advised to carefully read this Privacy Notice before using or
          availing any of our products and/or services. <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            {' '}
            Definitions
          </h1>
          <hr className="w-auto py-2" />
          In this Privacy Notice, the following definitions are used: Cookies a
          small file placed on your device by our website or mobile application
          when you either visit or use certain features of our website or mobile
          application. A cookie generally allows a website to remember your
          actions or preference for a certain period of time. Data includes
          non-personal information, personal information and sensitive personal
          information about you, which either directly or indirectly in
          combination with other information, could allow you to be identified
          when you visit our stores, website and/or mobile application. Data
          Protection Laws any applicable law for the time being in force
          relating to the processing of Data. Partners select third parties with
          whom we have contracts for the businesses described in this Privacy
          Notice. Service Providers includes entities to whom we will disclose
          your Data in order to process information for a specific purpose
          pursuant to written contract.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1"> Vyloo </h1>
          <hr className="w-auto py-2" />
          Vyloo Technologies Private Limited , a company incorporated in India
          whose registered office is at 10251, Gali Mill Wali, Library Road,
          Azad Market, Delhi, 110006. User or You The natural person who
          accesses our stores, website or mobile application.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            {' '}
            What data do we collect about you
          </h1>
          <hr className="w-auto py-2" />
          Vyloo collects Data for various purposes set out in this Privacy
          Notice. This Data includes, without limitation, the following
          categories: <br />
          <span className="text-[#16496F] font-semibold">
            Contact information:
          </span>{' '}
          first and last name, email address, postal address, country, employer,
          phone number and other similar contact data. <br />
          <span className="text-[#16496F] font-semibold">
            Financial information:
          </span>{' '}
          Payment instrument information, transactions, transaction history,
          preferences, method, mode and manner of payment, spending pattern or
          trends, and other similar data. <br />
          <span className="text-[#16496F] font-semibold">
            Technical information:
          </span>{' '}
          website, device and mobile app usage, Internet Protocol (IP) address
          and similar information collected via automated means, such as
          cookies, pixels and similar technologies.
          <br />
          <span className="text-[#16496F] font-semibold">
            Transaction information:
          </span>{' '}
          the date of the transaction, total amount, transaction history and
          preferences and related details. Health related information, such as
          information or records relating to Your medical/ health history,
          health status, details of treatment plans and medication prescribed by
          a Medical Practitioner, dosage details such as frequency of dosage,
          alternative medication, medicines ordered by you through the platform,
          laboratory testing results and any other information inferred there
          from.
          <br />
          <span className="text-[#16496F] font-semibold">
            Product and service information:
          </span>{' '}
          Your account membership number, registration and payment information,
          and program-specific information, when you request products and/or
          services directly from us, or participate in marketing programs.{' '}
          <br />
          <span className="text-[#16496F] font-semibold">
            Personal information:
          </span>{' '}
          Age, sex, date of birth, marital status, nationality, details of
          government identification documents provided, occupation, ethnicity,
          religion, travel history or any other personal information provided in
          responses to surveys or questionnaires. Your reviews, feedback and
          opinions about our products, programmes and services. Loyalty
          programme information: your loyalty membership information, account
          details, profile or password details and any frequent flyer or travel
          partner programme affiliation.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            How we collect data
          </h1>
          <hr className="w-auto py-2" />
          We collect Data in the following ways: Information You Give Us: We
          receive and store any information you enter on our website or mobile
          application or give us in any other way (e.g., at outlets, stores,
          hotels, kiosks). Please see the section titled "Data Shared by You"
          for more information. Automatic Information We Collect: We use
          "cookies", pixels and similar technologies to receive and store
          certain types of information whenever you interact with us. Please see
          the section below, titled "Data that is Collected Automatically" for
          more information. E-mail Communications: To help us make e-mails more
          relevant and interesting, we often receive a confirmation (if your
          device supports such capabilities) when you open e-mail from us or
          click on a link in the e-mail. You can choose not to receive marketing
          emails from us by clicking on the unsubscribe link in any marketing
          email. Automatic Information We Collect from Other Websites: We
          receive and store certain types of information when you interact with
          third-party websites that use our technology or with whom we have a
          specific agreement. Because we process this information on behalf of
          the applicable website operators, collection, processing, and use of
          such information is subject to the applicable website operators’
          privacy policies and is not covered by our Privacy Notice. Information
          from Other Sources: We may obtain information from other sources. An
          example of this is when you authorize a third-party website, to
          interact directly with our website or mobile application to provide or
          receive Data about you. In that case, we might receive such Data used
          by that third-party website to identify your account with that
          website. You can make choices about our collection and use of your
          Data. For example, you may want to access, edit or remove your Data on
          our website or mobile application. When you are asked to provide Data,
          you may decline.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Data shared by you
          </h1>
          <hr className="w-auto py-2" />
          Vyloo may collect your Data in several ways from your use of our
          stores, website or mobile application. For instance: when you register
          with us to receive our products and/or services; when you conduct a
          transaction with us or attempt a transaction at our stores, on our
          website or mobile application; when you complete surveys conducted by
          or for us; when you elect to receive any communications (including
          promotional offers) from us; from the information gathered by your
          visit to our stores, website or mobile application.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Data that is collected automatically
          </h1>
          <hr className="w-auto py-2" />
          We automatically collect some information when you visit our website
          or use our mobile application. This information helps us to make
          improvements to our content and navigation. The information collected
          automatically includes your IP address. Our web servers or affiliates
          who provide analytics and performance enhancement services collect IP
          addresses, operating system details, browsing details, device details
          and language settings. This information is aggregated to measure the
          number of visits, average time spent on the site, pages viewed and
          similar information. Vyloo uses this information to measure the site
          usage, improve content and to ensure safety and security, as well as
          enhance performance of our website or mobile application. We may
          collect your Data automatically via Cookies, pixels and similar
          technologies in line with settings on your browser. For more
          information about Cookies, please see the section below, titled
          "Cookies". <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Our use of data
          </h1>
          <hr className="w-auto py-2" />
          Any or all the above Data may be required by us from time to time to
          provide information relating to Vyloo and to work on the experience
          when using our website or mobile application. Specifically, Data may
          be used by us for the following reasons: carry out our obligations
          arising from any contract entered into between you and us; provide
          products and/or services and communicate with you about products
          and/or services offered by us; enable Partners to offer their products
          and/or services and communicate with you about such products and/or
          services; processing, disclosing, transmitting, and/or sharing the
          data/information with other third parties which have business or
          contractual dealings with us; provide you with offers (including for
          financial products and/or services), personalized services and
          recommendations and improve your experience on our website and mobile
          application; operate, evaluate and improve our business, website and
          mobile application; generate aggregated data to prepare insights to
          enable us to understand customer behaviour, patterns and trends with a
          view to learning more about your preferences or other characteristics;
          provide privileges and benefits to you, marketing and promotional
          campaigns based on your profile; in connection with loyalty programs
          owned and operated by us; communicate with you (including to respond
          to your requests, questions, feedback, claims or disputes) and to
          customize and improve our services; to enhance your shopping
          experience and bring you access to membership programs, rewards and
          offers across Vyloo partners. enforce the terms of use of our website
          and mobile application; protect against and prevent fraud, illegal
          activity, harm, financial loss and other legal or information security
          risks; and serve other purposes for which we provide specific notice
          at the time of collection, and as otherwise authorized or required by
          applicable law. We treat these inferences as personal information (or
          sensitive personal information, as the case may be), where required
          under applicable law. Some of the above grounds for processing will
          overlap and there may be several grounds which justify our use of your
          personal information. Where required under applicable law, we will
          only use your personal information (including sensitive personal
          information) with your consent; as necessary to provide you with
          products and/or services; to comply with a legal obligation; or when
          there is a legitimate interest that necessitates the use.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1"> Minors</h1>
          <hr className="w-auto py-2" />
          Our website and mobile application do not offer products or services
          for use by minors. If you are under 18, you may use our website or
          mobile application only with the involvement of a parent or guardian.{' '}
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Sharing of data
          </h1>
          <hr className="w-auto py-2" />
          We may share your Data with/ for: Partners: We may make available to
          you services, products, or applications provided by Partners for use
          on or through our website or mobile application. If you choose to use
          such service, customer information related to those transactions may
          be shared with such Partner. Such Partners will be required to respect
          the security of your Data and to treat it in accordance with this
          privacy policy and applicable law. <br />
          Service Providers: We or other Tata Group Entities may share your Data
          with Service Providers. Examples include storing and analyzing Data,
          protecting and securing our systems, providing search results and
          links, providing customer service, credit analysis, processing your
          information for profiling, user analysis and payment processing.
          <br />
          <br />
          Information from Other Sources: We may obtain information from other
          sources. An example of this is when you authorize a third-party
          website, to interact directly with our website or mobile application
          to provide or receive Data about you. In that case, we might receive
          such Data used by that third-party website to identify your account
          with that website.
          <br />
          <br />
          These Service Providers will be required to only process Data in
          accordance with express instructions and as necessary to perform
          services for purposes set forth in this Privacy Notice. The Service
          Providers will also be required to safeguard the security and
          confidentiality of the Data they process by implementing appropriate
          technical and organizational security measures and confidentiality
          obligations binding employees accessing Data. <br />
          Protecting Vyloo: We may release Data when we believe release is
          appropriate to comply with applicable law or legal process, enforce or
          apply the Terms of Use of our website or mobile application and other
          agreements, protect Vyloo against harm or financial loss, when we
          believe disclosure is necessary to protect individuals’ vital
          interests, or in connection with an investigation of suspected or
          actual fraudulent or illegal activity. This may include exchanging
          information with other companies and organizations for fraud
          protection, risk management and dispute resolution. This does not
          include selling or otherwise disclosing personal information of users
          for commercial purposes in violation of this Privacy Notice. Business
          Transfers: As we continue to develop our business, we might sell or
          buy subsidiaries or business units. Your Data (including in relation
          to loyalty programs) may be transferred as part of such transaction.
          Any Data that we receive from a third party pursuant to such
          transactions will be processed in accordance with this Privacy Notice
          and applicable law.
          <br />
          <br />
          Third Parties: We may also share your Data with other third parties
          where: You request or authorize us to do so; We need to comply with
          applicable law or respond to valid legal process; or We need to
          operate and maintain the security of our website or mobile
          application, including to prevent or stop an attack on our computer
          systems or networks. We require these third parties by contract to
          only process sensitive personal data in accordance with our
          instructions and as necessary to perform services on our behalf or in
          compliance with applicable law. We also require them to safeguard the
          security and confidentiality of the sensitive personal data they
          process on our behalf by implementing appropriate confidentiality,
          technical and organizational security measures.
          <br />
          <br />
          Please note that Partners may have privacy practices that differ from
          those of Vyloo. The use of your Data will be governed by their privacy
          statements when you provide Data on their websites.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Keeping data secure
          </h1>
          <hr className="w-auto py-2" />
          We will use technical and organisational measures to safeguard your
          Data and we store your Data on secure servers. Technical and
          organisational measures include measures to deal with any suspected
          data breach. If you suspect any misuse or loss or unauthorised access
          to your Data, please let us know immediately by contacting us by
          e-mail at our email address provided at Clause 16 below.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Retention of data
          </h1>
          <hr className="w-auto py-2" />
          Vyloo retains Data for as long as necessary for the use of our
          products and/or services or to provide access to and use of our
          website or mobile application, or for other essential purposes such as
          complying with our legal obligations, resolving disputes, enforcing
          our agreements and as long as processing and retaining your Data is
          necessary and is permitted by applicable law. Because these needs can
          vary for different data types and purposes, actual retention periods
          can vary significantly. Even if we delete your Data, including on
          account of exercise of your right under Clause 10 below, it may
          persist on backup or archival media for audit, legal, tax or
          regulatory purposes.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Your rights and choices
          </h1>
          <hr className="w-auto py-2" />
          When we process Data about you, we do so with your consent and/or as
          necessary to operate our business, meet our contractual and legal
          obligations, protect the security of our systems and our customers, or
          fulfil other legitimate interests of Vyloo as described in this
          Privacy Notice.
          <br />
          You have the following rights in relation to your sensitive personal
          information and you can exercise it by submitting a request as
          described in the "How to Contact Us" section below.
          <br />
          Right to Access, Review and Modify
          <br />
          Right to Correction
          <br />
          Right to Withdraw Consent
          <br />
          It is important that the Data we hold about you is accurate and
          current. Please keep us informed if your personal information changes
          during the period for which we hold it.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Where we store data
          </h1>
          <hr className="w-auto py-2" />
          Data collected under this Privacy Notice is hosted on servers located
          in India. <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Processing your data
          </h1>
          <hr className="w-auto py-2" />
          We take steps to ensure that the Data we collect under this Privacy
          Notice is processed according to the provisions of this Privacy Notice
          and the requirements of applicable law. To ensure that your Data
          receives an adequate level of protection, we have put in place
          appropriate written contracts with Partners and Service Providers that
          we share your Data with. This ensures your Data is treated by such
          parties in a way that is consistent with applicable law.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            App permissions that we capture
          </h1>
          <hr className="w-auto py-2" />
          We ask for the following app permissions while onboarding, in order to
          optimize the experience for you: Location It is recommended that you
          set your location sharing 'Always' as it helps us to show you location
          specific data like availability of products. Any data shared by you
          will not be misused. You can change this anytime. Camera To allow you
          to take a photo of prescriptions & directly upload it to the app.
          Photos/Media/Files Media access permission is needed to store and
          retrieve your uploads such as prescription uploads on your device. SMS
          To support automatic OTP confirmation, so that you don't have to enter
          the authentication code manually. Receive SMS This helps us to send
          you payment related SMS by our payment partner PayU.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            {' '}
            Severability
          </h1>
          <hr className="w-auto py-2" />
          If any court or competent authority finds that any provision of this
          Privacy Notice (or part of any provision) is invalid, illegal or
          unenforceable, that provision or part-provision will, to the extent
          required, be deemed to be deleted, and the validity and enforceability
          of the other provisions of this Privacy Notice will not be affected.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Changes to this privacy notice
          </h1>
          <hr className="w-auto py-2" />
          Our business changes constantly and our Privacy Notice may also change
          . We may e-mail periodic reminders of our notices and conditions,
          unless you have instructed us not to, but you should check our website
          and mobile application frequently to see recent changes. The updated
          version will be effective as soon as it is accessible. Any changes
          will be immediately posted on our website and mobile application and
          you are deemed to have accepted the terms of the updated Privacy
          Notice on your first use of our website or mobile application or first
          purchase of the products and/or services following the alterations. We
          encourage you to review this Privacy Notice frequently to be informed
          of how we are protecting your information.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            How to contact us
          </h1>
          <hr className="w-auto py-2" />
          To request to access, review, update, or withdraw your consent for
          your personal information or to otherwise reach us, please submit a
          request by e-mailing us at support@vyloo.in . You may contact us for
          information on Service Providers and Partners with whom we may share
          your Data in compliance with this Privacy Notice and applicable law.
          We will respond to your request within 30 days.
          <br />
          <br />
          <h1 className="text-2xl font-black text-[#16496F] py-1">
            Grievance Officer
          </h1>
          <hr className="w-auto py-2" />
          Please see below the details of our grievance officer:
          <br />
          Name: Mr. Pulkit Gupta <br />
          Email: grievanceofficer@vylootechnologies.in <br />
          Address: Grievance Officer,10251, Gali Mill Wali, Library Road, Azad
          Market, Delhi, 110006.
        </p>
      </div>
    </>
  );
}
