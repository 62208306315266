import React from 'react';
import MNavbar from '../components/navbar-merchants';
import img from '../assets/merchant.webp';
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';
import m2 from '../assets/2.webp';

export default function VylooCustomers() {
  return (
    <>
      <MNavbar />
      <div className="p-2 lg:p-8 text-center">
        <img src={img} className="rounded-xl w-[full]" alt="vyloo logo" />
      </div>
      <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between bg-white px-4 py-0 lg:px-24 lg:py-12 rounded-lg shadow-lg">
        <div className="md:w-1/2 mt-12 mb-4 md:mb-0 md:mr-4">
          <h2 className="text-3xl font-black mb-2 text-[#16496F]">
            Want to take your shop{' '}
            <span className="text-[#089DD5]">Online</span> ?
          </h2>
          <p className="text-gray-500 mb-4 text-lg py-4">
            Get Vyloo Merchant App and register your shop online. With Vyloo
            Merchants, you can sell & manage orders efficiently, on an online
            platform and reach a wider audience.
          </p>
          <div className="flex space-x-8">
            {/* <a href="">
            <button class="bg-[#16496F] transition duration-300 ease-in-out hover:bg-[#089DD5] text-white font-semibold  py-2 px-4 rounded-md  inline-flex items-center">
              <FaAppStore className="w-8 h-8" />
              <span className="text-[0.8rem] text-left pl-4">
                Download on <br />{" "}
                <span className="text-sm lg:text-[1.2rem]">App Store</span>
              </span>
            </button>
           </a> */}
            <a href="https://play.google.com/store/apps/details?id=com.vylooTech.vyloo_seller&hl=en_US">
              <button class="bg-[#16496F] transition duration-300 ease-in-out hover:bg-[#089DD5] text-white font-semibold  py-2 px-4 rounded-md  inline-flex items-center">
                <FaGooglePlay className="w-7 h-7" />
                <span className="text-[0.8rem] text-left pl-4">
                  Get it on
                  <br />{' '}
                  <span className=" text-sm lg:text-[1.2rem]">Google Play</span>
                </span>
              </button>
            </a>
          </div>
        </div>

        <div className="md:w-1/4 mr-28 hidden md:block">
          <img src={m2} alt="iPhone Mockup" className="w-full" />
        </div>
      </div>
    </>
  );
}
