import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import UpdateCustomerRequest from './UpdateCustomerRequest';

const CustomerRequestDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCustomerRequestDetails = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');

        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getCustomerRequestDetails',
          {
            customerRequestId: id,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setDetails(response.data.data.customerRequestDetails);
        } else {
          setError('Failed to fetch customer request details.');
        }
      } catch (error) {
        setError('An error occurred while fetching customer request details.');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerRequestDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!details) return <p>No details found.</p>;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Customer Request Details</h1>
      <div className="bg-white shadow-md rounded-lg p-4">
        <h2 className="text-xl font-semibold mb-4">Request Information</h2>
        <p>
          <strong>Ticket ID:</strong> {details.ticketId}
        </p>
        <p>
          <strong>Status:</strong> {details.status}
        </p>
        <UpdateCustomerRequest
          customerRequestId={details.customerRequestId}
          currentStatus={details.status}
        />
        <p>
          <strong>Raised Time:</strong>{' '}
          {new Date(details.raisedTime).toLocaleString()}
        </p>
        <p>
          <strong>Description:</strong> {details.description}
        </p>
        <p>
          <strong>User ID:</strong>

          {details.userId}
        </p>
        <Link
          to={`/user-order-history/${details.userId}`}
          className="text-blue-500 hover:underline"
        >
          <button className="mt-2 px-4 py-2 mr-4 font-bold text-white bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline">
            View Order History
          </button>
        </Link>

        <h2 className="text-xl font-semibold mt-6 mb-4">Order Details</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                  Receiver Name
                </th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                  Receiver Phone Number
                </th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                  Address Line 1
                </th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                  Address Line 2
                </th>
                <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                  Payment Method
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {details.orderDetails.length > 0 ? (
                details.orderDetails.map((order) => (
                  <tr key={order._id} className="hover:bg-gray-100">
                    <td className="py-3 px-4">{order.receiverName}</td>
                    <td className="py-3 px-4">{order.receiverPhoneNumber}</td>
                    <td className="py-3 px-4">{order.addressLine1}</td>
                    <td className="py-3 px-4">{order.addressLine2}</td>
                    <td className="py-3 px-4">{order.paymentMethod}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="py-3 px-4" colSpan="5">
                    No order details found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomerRequestDetails;
