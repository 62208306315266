// Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './footer'; // Adjust the path as needed

const Layout = ({ children }) => {
  const location = useLocation();

  // List of paths where the footer should not be displayed
  const noFooterPaths = ['/payment-success', '/payment-failed', '/admin'];

  // Check if the current path is in the list of paths to hide the footer
  const showFooter = !noFooterPaths.includes(location.pathname);

  return (
    <div>
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
