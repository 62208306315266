// App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from './pages/home';
import Privacy from './pages/privacy';
import Contact from './pages/contact';
import Merchants from './pages/for-merchants';
import Delivery from './pages/for-delivery';
import MerchantTC from './pages/merchant-terms-conditions';
import DeliveryTC from './pages/delivery-terms-conditions';
import Download from './pages/download-app';
import TermsConditions from './pages/terms-conditions';
import PaymentSuccess from './pages/success_payment';
import PaymentFailed from './pages/fail_payment';
import Layout from './components/Layout';
import AdminLogin from './pages/admin/AdminLogin';
import Dashboard from './pages/admin/Dashboard';
import SupportRequests from './pages/support/SupportRequests';
import CustomerRequestDetails from './pages/customer-support/CustomerRequestDetails';
import UserOrderHistory from './pages/customer-support/UserOrderHistory';
import OrderStatus from './pages/admin/OrderStatus';
import Categories from './pages/categories/categories';
import SubCategories from './pages/categories/subcategories';
import Medicines from './pages/categories/medicines';
import Otc from './pages/categories/otc';
import ProductsList from './pages/categories/productList';
import OnlineMerchants from './pages/admin/onlineMerchants';
import OnlineDeliveryPartners from './pages/admin/onlineDeliveryPartners';
import SplitSettlementDetails from './pages/admin/SplitPayments';
import NotFound from './pages/notFound';
import { useAuth } from './AuthContext';
import DecathUrl from './pages/decath-url';

function App() {
  const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : <Navigate to="/adminLogin" />;
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/privacy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsConditions />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="/merchants"
          element={
            <Layout>
              <Merchants />
            </Layout>
          }
        />
        <Route
          path="/delivery"
          element={
            <Layout>
              <Delivery />
            </Layout>
          }
        />
        <Route
          path="/merchant-terms-and-conditions"
          element={
            <Layout>
              <MerchantTC />
            </Layout>
          }
        />
        <Route
          path="/delivery-terms-and-conditions"
          element={
            <Layout>
              <DeliveryTC />
            </Layout>
          }
        />
        <Route
          path="/download"
          element={
            <Layout>
              <Download />
            </Layout>
          }
        />
        <Route
          path="/register-decathlon"
          element={
            <Layout>
              <DecathUrl />
            </Layout>
          }
        />
        <Route
          path="/payment-success"
          element={
            <Layout>
              <PaymentSuccess />
            </Layout>
          }
        />
        <Route
          path="/payment-failed"
          element={
            <Layout>
              <PaymentFailed />
            </Layout>
          }
        />
        <Route
          path="/adminLogin"
          element={
            <Layout>
              <AdminLogin />
            </Layout>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/admin/support-requests" element={<SupportRequests />} />
        <Route
          path="/admin/customer-request/:id"
          element={<CustomerRequestDetails />}
        />
        <Route path="/user-order-history/:id" element={<UserOrderHistory />} />
        <Route path="/admin/order-status" element={<OrderStatus />} />
        <Route path="/admin/categories" element={<Categories />} />
        <Route
          path="admin/subcategories/:categoryId"
          element={<SubCategories />}
        />
        <Route
          path="/subcategory/:subCategoryId/products"
          element={<ProductsList />}
        />
        <Route path="admin/medicines" element={<Medicines />} />
        <Route path="admin/otc" element={<Otc />} />
        <Route path="admin/merchants-online" element={<OnlineMerchants />} />
        <Route
          path="/admin/delivery-partners-online"
          element={<OnlineDeliveryPartners />}
        />
        <Route
          path="admin/split-settlement-details"
          element={<SplitSettlementDetails />}
        />
        <Route
          path="*"
          element={
            <Layout>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
