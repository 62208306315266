import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';
import Navbar from '../components/navbar';

export default function Contact() {
  const form = useRef();
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const [formData, setFormData] = useState({
    user_fname: '',
    user_lname: '',
    user_email: '',
    user_phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.user_email)) {
      alert('Please enter a valid email address.');
      return;
    }

    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        console.log(result.text);
        console.log('message sent');
        toast.success('Message successfully sent!');

        // Clear form after successful submission
        setFormData({
          user_fname: '',
          user_lname: '',
          user_email: '',
          user_phone: '',
          message: '',
        });
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <>
      <Navbar />
      <div className="relative grid w-full bg-contact bg-no-repeat bg-top lg:bg-cover mt-[-120px] h-[32rem] items-center z-[-999] opacity-[100%]">
        <div className="px-[10%] py-8">
          <div className="items-center flex">
            <div className="w-full lg:w-1/2">
              <h2 className="text-3xl font-extrabold text-[#F5F5F5] sm:text-4xl">
                <br />
                <br />
                <span className="block lg:text-left text-center ">
                  Have any query ? We are <br />
                  here to help
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 place-items-center py-2 lg:py-32">
        <div className="p-8 lg:text-left text-center">
          <h2 className="text-3xl font-extrabold text-[#16496F] sm:text-4xl">
            <span className="block">Contact us</span>
          </h2>

          <p className="mt-3 text-lg text-gray-500">
            We&apos;d like to hear from you. Please fill out this form or send
            us an email.
          </p>
        </div>

        <div className="lg:p-8 p-4 w-[80%] lg:w-[65%] py-6 rounded-lg bg-gray-100">
          <form ref={form} onSubmit={sendEmail}>
            <div className="-mx-2 md:items-center md:flex">
              <div className="flex-1 px-2">
                <label className="block mb-2 text-sm text-gray-600">
                  First Name
                </label>
                <input
                  type="text"
                  name="user_fname"
                  value={formData.user_fname}
                  onChange={handleChange}
                  placeholder="First Name"
                  className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-[#089DD5] focus:ring-[#089DD5] focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>

              <div className="flex-1 px-2 mt-4 md:mt-0">
                <label className="block mb-2 text-sm text-gray-600">
                  Last Name
                </label>
                <input
                  type="text"
                  name="user_lname"
                  value={formData.user_lname}
                  onChange={handleChange}
                  placeholder="Last Name"
                  className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-[#089DD5] focus:ring-[#089DD5] focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
            </div>

            <div className="mt-4">
              <label className="block mb-2 text-sm text-gray-600">
                Email address
              </label>
              <input
                type="email"
                name="user_email"
                value={formData.user_email}
                onChange={handleChange}
                placeholder="example@example.com"
                required
                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-[#089DD5] focus:ring-[#089DD5] focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div className="mt-4">
              <label className="block mb-2 text-sm text-gray-600">
                Phone Number
              </label>
              <input
                type="tel"
                pattern="\d{10}"
                title="Enter a valid 10-digit phone number"
                name="user_phone"
                value={formData.user_phone}
                onChange={handleChange}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, '')
                    .slice(0, 10);
                }}
                placeholder="9999999999"
                required
                className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-[#089DD5] focus:ring-[#089DD5] focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div className="w-full mt-4">
              <label className="block mb-2 text-sm text-gray-600">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-[#089DD5] focus:ring-[#089DD5] focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Message"
              ></textarea>
            </div>

            <input
              type="submit"
              value="Send"
              className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-[#16496F] rounded-lg hover:bg-[#089DD5] focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            />
          </form>
        </div>
      </div>
    </>
  );
}
