import React, { useState } from 'react';
import axios from 'axios';

const SplitSettlementDetails = () => {
  const [orderId, setOrderId] = useState('');
  const [splitSettlementDetails, setSplitSettlementDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchSplitSettlementDetails = async () => {
    if (!orderId) {
      setError('Please enter a valid Order ID');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getOrderSplitSettlementDetails',
        { orderId },
        {
          headers: {
            Authorization: `${sessionStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.data.success) {
        setSplitSettlementDetails(
          response.data.data.orderSplitSettlementDetails
        );
      } else {
        setError('Failed to fetch split settlement details.');
      }
    } catch (error) {
      setError(
        'An error occurred while fetching the split settlement details.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Split Settlement Details</h1>

      <div className="mb-6">
        <input
          type="text"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
          placeholder="Enter Order ID"
          className="p-2 border border-gray-300 rounded"
        />
        <button
          onClick={fetchSplitSettlementDetails}
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Search
        </button>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {splitSettlementDetails && (
        <div className="bg-white shadow-md rounded-lg p-4">
          {splitSettlementDetails.map((settlement) => (
            <div key={settlement._id} className="mb-6">
              <h2 className="text-xl font-semibold mb-4">Settlement Details</h2>
              <p>
                <strong>Settlement Amount:</strong> ₹
                {settlement.splitSettlementDetails.settlementAmount}
              </p>
              <p>
                <strong>Merchant Aggregator Sub Amount:</strong> ₹
                {settlement.splitSettlementDetails.merchantAggregatorSubAmt}
              </p>
              <p>
                <strong>Delivery Partner Aggregator Sub Amount:</strong> ₹
                {
                  settlement.splitSettlementDetails
                    .deliveryPartnerAggregatorSubAmt
                }
              </p>
              <p>
                <strong>Total Delivery Charges:</strong> ₹
                {settlement.splitSettlementDetails.totalDeliveryCharges}
              </p>
              <p>
                <strong>Handling Charges:</strong> ₹
                {settlement.splitSettlementDetails.handlingCharges}
              </p>
              <p>
                <strong>Distance:</strong>{' '}
                {settlement.splitSettlementDetails.distance} meters
              </p>
              <p>
                <strong>Duration:</strong>{' '}
                {settlement.splitSettlementDetails.duration} seconds
              </p>
              <p>
                <strong>Settlement Status:</strong>{' '}
                {settlement.splitSettlementDetails.settlementStatus}
              </p>
              <p>
                <strong>Payment Method:</strong>{' '}
                {settlement.splitSettlementDetails.paymentMethod}
              </p>
              <p>
                <strong>Status:</strong>{' '}
                {settlement.splitSettlementDetails.status}
              </p>
              <p>
                <strong>Created On:</strong>{' '}
                {new Date(
                  settlement.splitSettlementDetails.createdOn
                ).toLocaleString()}
              </p>
              <p>
                <strong>Last Updated:</strong>{' '}
                {new Date(
                  settlement.splitSettlementDetails.updatedAt
                ).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SplitSettlementDetails;
