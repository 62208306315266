// Navbar.js
import React from 'react';
import { FaBars } from 'react-icons/fa';
import Logo from '../../assets/logo.svg'; // Update with the actual path to your logo

const Navbar = ({ getAdminName, logout }) => {
  const [isOpen4, setIsOpen4] = React.useState(false);

  return (
    <nav className="bg-[#1D6EAA] p-4">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="text-white font-bold">
          <img src={Logo} className="h-5" alt="logo" />
        </a>
        <div className="hidden md:flex space-x-4">
          <span className="text-white px-3 cursor-default">
            Welcome&nbsp;&nbsp;{getAdminName}
          </span>
          {/* <a href="/admin/dashboard" className="text-white px-3 cursor-pointer">
            Dashboard
          </a> */}
          <span onClick={logout} className="text-white px-3 cursor-pointer">
            Logout
          </span>
        </div>
        <button
          className="text-white md:hidden"
          onClick={() => setIsOpen4(!isOpen4)}
        >
          <FaBars />
        </button>
      </div>
      {isOpen4 && (
        <div className="md:hidden mt-2">
          <span className="block text-white px-3 py-2 cursor-default">
            Welcome&nbsp;&nbsp;{getAdminName}
          </span>
          {/* <a href="/admin/dashboard" className="text-white px-3 cursor-pointer">
            Dashboard
          </a> */}
          <span
            onClick={logout}
            className="block text-white px-3 cursor-pointer"
          >
            Logout
          </span>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
