import React from 'react';
import Navbar from '../components/navbar';
import Hero from '../components/hero';
import Features from '../components/features';
import Download from '../components/download';
import Section1 from '../components/section-1';
import Customers from '../components/for-customers';

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <Download />
      <Features />
      <Section1 />
      <Customers />
    </>
  );
}
