import React from 'react';
import MNavbar from '../components/navbar-merchants';

export default function MerchantTC() {
  return (
    <>
      <MNavbar />
      <div className="px-8 lg:px-28 text-justify">
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Merchant Terms and Conditions
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          This document is an electronic record in terms of the Information
          Technology Act 2000 and rules thereunder as applicable and the amended
          provisions pertaining to electronic records in various statutes as
          amended by the Information Technology Act 2000. This document is
          published in accordance with the provisions of Rule 3 (1) of the
          Information Technology (Intermediaries guidelines) Rules 2011 that
          require publishing the rules and regulations, privacy policy, and
          Merchant T&C (as defined below) for access or usage of ‘www.vyloo.in’
          website and ‘Vyloo’ application for mobile and handheld devices.
          <br /> <br />
          Any Capitalized terms used but not defined herein shall have the
          meaning assigned to them under the (i) services agreement executed
          between Vyloo and the Merchant and (ii) Terms of Use as amended from
          time to time which govern your use of our website ‘www.vyloo.in’ (the
          “Website”) and our ‘Vyloo’ application for mobile and handheld devices
          (the “App”).
          <br /> <br />
          You wish to avail the Platform Services enabled by Vyloo on a
          non-exclusive and contractual basis subject to the terms and
          conditions set out hereinafter.
          <br /> <br />
          Merchant and Vyloo are referred to individually as ‘Party’ and
          collectively as ‘Parties’ wherever the context so requires.
          <br /> <br />
          <span className="font-bold text-black">I.</span> These terms are
          called the “Merchant T&C” and these Merchant T&C read together with
          Terms of Use and the agreements executed between the Parties shall
          govern your use of Platform. In the event of any inconsistency between
          the documents/agreements, the terms of the agreement will prevail over
          these Merchant T&C which will prevail over the Terms of Use. Please
          read these Merchant T&C carefully before you use the Platform
          Services. If you do not agree to these Merchant T&C you must not use
          the Platform Services and we request you to uninstall the App. By
          installing, downloading, or even merely using the Platform you shall
          be contracting with Vyloo and you signify your acceptance to this
          Merchant Terms, Terms of Use, and other Vyloo policies (including but
          not limited to the Privacy Policy being incorporated by reference
          herein) which take effect on the date on which you download, install,
          or use the Platform Services and create legally binding arrangements
          to abide by the same.
          <br /> <br />
          <span className="font-bold text-black">II.</span> These Merchant T&C
          along with the services agreement or any other agreement signed
          between the Parties shall constitute the entire agreement between the
          Parties in relation to the Platform Services.
          <br /> <br />
          <span className="font-bold text-black">III.</span> The Platform is
          owned and operated by Vyloo Technologies Private Limited, a company
          incorporated under the laws of India and having its registered office
          at 10251, Gali Mill Wali, Library Road, Azad Market, Delhi, India. For
          the purpose of these Merchant Terms wherever the context so requires
          “you” or “user” shall mean any natural or legal person who has agreed
          to become a merchant on the Platform by providing registration data
          during the registration/ onboarding on the Platform as a registered
          partner using the computer systems. The term “Vyloo,” “we,” “us,” or
          “our'' shall mean Vyloo Technologies Private Limited.
          <br /> <br />
          <span className="font-bold text-black">IV.</span> Vyloo enables
          transactions between the buyers (“Buyers”) and participant merchants
          dealing in selling of Medicines, Medicinal Products, Fmcg, OTC
          products, etc. The Buyers who visit the Platform can choose and place
          orders (“Orders”) from a variety of products made available for sale
          by various merchants like you (“Merchant/s”) on the Platform.
          <br /> <br />
          <span className="font-bold text-black">V.</span> Vyloo also enables
          Facilitation Services i.e. transactions between the pick-up and
          delivery partner (“PDP”) and the Buyers for delivery of such Orders at
          select localities of serviceable cities across India.
          <br /> <br />
          <span className="font-bold text-black">VI.</span> These Merchant T&C
          are subject to modifications and we reserve the right to modify or
          change the Merchant T&C and other Vyloo policies at any time by
          posting changes on the Platform and we may intimate you of the
          changes. Vyloo might choose to communicate such modifications in a
          separate email and any other form of communication to you for your
          ready reference. However, the Merchant shall at all times be
          responsible for regularly reviewing the Merchant T&C and other Vyloo
          policies and note the changes made on the Platform, including but not
          limited to this page. Your continued use of the Platform and the
          Platform Services after any change is posted or shared with you
          confirms your acceptance of the amended Merchant T&C and other Vyloo
          policies.
          <br />
          <br />
          <span className="font-bold text-black">
            VII. General Conditions
          </span>{' '}
          <br />
          <br /> a. All commercial/contractual terms are offered by you and
          agreed by the Buyers alone with respect to product and services you
          provide on the Platform. Vyloo is only providing a platform for
          communication and facilitation and it is agreed that the contract for
          the sale of any of the products or services shall be a strictly
          bipartite contract between the Merchant and the Buyer. The
          commercial/contractual terms include without limitation the price of
          the products, applicable taxes, shipping costs, payment methods,
          payment terms, date, period, and mode of delivery, quality and
          warranties related to products, and after-sales services related to
          products. Vyloo does not have any control or does not determine or
          advise or in any way involve itself in the offering or acceptance of
          such commercial/contractual terms between the Buyers and Merchants.{' '}
          <br />
          <br />
          b. Vyloo does not make any representations, guarantees, or warranties
          of the products sold by the Merchants on the Platform to the Buyers.{' '}
          <br />
          <br />
          c. Vyloo is not responsible for any non-performance or breach of any
          contract entered into between Buyers and Merchants or Buyers and PDPs
          or Merchant and PDPs on the Platform. Vyloo cannot and does not
          guarantee that the concerned parties will perform their part of the
          obligation in any transaction concluded on the Platform. Vyloo is not
          responsible for non-performance or unsatisfactory services or damages
          or delays as a result of products which are out of stock, unavailable,
          or back-ordered. <br />
          <br />
          d. Vyloo is operating an online marketplace and assumes the role of
          facilitator only. Vyloo shall not hold any title or interest over the
          products nor shall Vyloo have any obligations or liabilities in
          respect of such contract entered into between Merchant and Buyer
          including but not limited to product liability. <br />
          <br />
          e. In case of complaints from the Buyer pertaining to product
          efficacy, quality, or any other such issues, Vyloo shall notify the
          same to the Merchant and shall also redirect the Buyer to the consumer
          call center wherever applicable. Merchant shall alone be liable for
          redressing and bound to take action on the complaints by the Buyer.
          Vyloo has the right to share with the Buyer the relevant information
          including the Merchant details to enable satisfactory resolution of
          the complaint. <br />
          <br />
          f. You agree that Vyloo is not responsible for any dealing with
          underage persons or people acting under false pretense. <br />
          <br />
          g. You agree, undertake, and confirm that your use of Platform shall
          be strictly governed by the binding principles more fully detailed in
          the Terms of Use. <br />
          <br />
          h. Vyloo may provide recommendations on various campaigns, product
          categories, and discounts based on analytics, data gathering
          exercises, etc. The Parties may mutually agree on various modalities
          for providing discounts if any on the products sold by the Merchant on
          the Platform from time to time. The Merchant shall in its sole
          discretion follow/not follow the recommendations of Vyloo. <br />
          <br />
          <span className="font-bold text-black">
            {' '}
            VIII. Terms and Conditions of Platform Services
          </span>{' '}
          <br />
          <br />
          a. Vyloo provides no assurance and/or guarantees towards a Buyer
          placing Orders with any Merchant/s. The Merchant shall be solely
          responsible for display of the name/details of the store and images of
          the items/products which shall be listed for the Buyers to choose
          from. The Merchant shall be liable to comply with Legal Metrology
          (Packaged Commodities) Rules 2011 and/or Food Safety and Standards
          (Labeling and Display) Regulations 2020 and Central Drugs Standard
          Control Organisation regulations as amended time to time for
          displaying such pre-packaged products and share with Vyloo legible and
          clear images of the ‘principal display panel’ of such pre-packaged
          products which shall be displayed on the Platform for the Buyers. It
          is clarified that Vyloo shall facilitate but does not and shall not
          verify the content shared by the Merchant for displaying. <br />
          <br />
          b. Vyloo shall merely facilitate the pick-up and delivery of the
          Orders on the Platform through the PDPs and the PDPs are at liberty to
          choose the means for the pick-up and delivery of the Orders placed by
          Buyers on the Platform. Vyloo is merely providing Delivery
          Facilitation Services between the PDP and the Buyer or Merchant as the
          case may be. Vyloo is not providing any delivery services whatsoever
          through itself either to the Merchant or to the Buyer. Notwithstanding
          anything else contained under these Merchant Terms, neither Party
          shall be liable for any unauthorized act of the PDPs and the liability
          shall solely rest with the PDPs. With respect to the Merchants
          providing their own delivery services, the liability arising out of
          such deliveries shall be that of respective Merchants and neither
          Vyloo nor PDPs are liable for the same. <br />
          <br />
          c. Notwithstanding anything else contained under these Merchant T&C,
          Vyloo shall not be responsible for any misconduct by the PDPs for eg.
          theft of the Order or any illegal activity or misconduct against/with
          the Buyer(s) and / or the staff of the Merchant or indulge in any
          vandalism against the Merchant. <br />
          <br />
          d. Vyloo shall at its sole discretion determine the areas where the
          Platform Services shall be provided by Vyloo via the Platform.
          Further, Vyloo reserves the right to at its sole discretion (i) add or
          remove any area as it may deem fit in order to improve Facilitation
          Services and (ii) choose the radius within which such Facilitation
          Services may be extended i.e. the maximum distance it may choose to
          extend Facilitation Services from where a Merchant may be located.
          <br />
          <br />
          e. Merchant shall be responsible to reimburse and/or absorb the cost
          of Orders for which Buyers have either refused to pay for or have
          claimed a refund for reasons that are solely attributed to Merchant
          (“Disputed Orders”) including but not limited to:
          <br />
          (i) wrong item or product delivered <br />
          (ii) tampered or damaged Orders
          <br /> (iii)expired products
          <br />
          (iv) missing Orders
          <br /> (v) defective products <br />
          (vi) delay in delivery due to Merchant’s failure to prepare the Order
          in time
          <br />
          (vii) items missing from the Order delivered <br />
          (viii) Merchant Products and/ or services do not conform to the
          Merchant listing information on the Platform or any other similar
          issue raised by the Buyer and the Merchant shall address such Disputed
          Orders solely at its own cost. Vyloo shall have no liability towards
          such Disputed Orders and the Merchant shall resolve such Disputed
          Orders with the Buyers directly.
          <br />
          <br />
          f. Vyloo shall have the right to reject listing of any product on the
          Platform at its sole discretion including for reasons not limited to
          product falling under the category of non-serviceable items as
          provided under applicable laws or product violating Vyloo’s internal
          policies and/or applicable laws. Further, Vyloo shall have the right
          to delist any Merchant or delist any products of the Merchant which
          fail to comply with applicable laws and regulations issued, any other
          packaging & labeling laws applicable to the products, which fail to
          comply with Vyloo’s internal policies or as may be instructed by the
          Regulatory Authorities. For instance, Vyloo shall reserve the right to
          delist a Merchant in the event the ratings of the Merchant falls below
          a certain threshold. Further Vyloo shall reserve the right to delist
          the products of the Merchant if the Merchant fails to display required
          information on the product packaging.
          <br />
          <br />
          g. In case the Merchant is selling any other products which require
          special licenses/permits, the Merchant shall ensure compliance with
          applicable laws including obtaining and maintaining valid
          licenses/permits for the sale of such products. The Merchant shall
          share such valid licenses/permits with Vyloo as and when required by
          Vyloo. Vyloo shall not be responsible for any liability arising out of
          the sale of such products by the Merchant including any penalty
          imposed by the Regulatory Authorities for non-compliance with
          applicable laws.
          <br />
          <br />
          h. The Merchant shall ensure timely fulfillment of the Orders placed
          by the Buyers on the Platform. The Merchant shall ensure that the
          Orders are prepared and handed over to the PDPs in a timely manner to
          ensure timely delivery to the Buyers. In case of any delay in the
          preparation of the Orders by the Merchant, the Merchant shall be
          liable for any claims or disputes raised by the Buyers for such
          delays. <br />
          <br />
          i. The Merchant shall ensure that the products listed on the Platform
          are available in sufficient quantities to fulfill the Orders placed by
          the Buyers. In case of any stock-out or unavailability of the
          products, the Merchant shall immediately update the stock status on
          the Platform to avoid any inconvenience to the Buyers. The Merchant
          shall be liable for any claims or disputes raised by the Buyers for
          non-availability of the products listed on the Platform. <br />
          <br />
          j. The Merchant shall ensure that the prices of the products listed on
          the Platform are accurate and up to date. The Merchant shall not
          charge any additional amount from the Buyers other than the price
          listed on the Platform. The Merchant shall be liable for any claims or
          disputes raised by the Buyers for overcharging or any discrepancy in
          the prices of the products listed on the Platform. <br />
          <br />
          k. The Merchant shall ensure that the products listed on the Platform
          are of good quality and comply with applicable laws. The Merchant
          shall be liable for any claims or disputes raised by the Buyers for
          defective, damaged, expired, or substandard products listed on the
          Platform. The Merchant shall address such claims or disputes directly
          with the Buyers and Vyloo shall not be responsible for the same.{' '}
          <br />
          <br />
          l. The Merchant shall ensure that the products listed on the Platform
          are properly packaged to avoid any damage during transit. The Merchant
          shall be liable for any claims or disputes raised by the Buyers for
          damaged products received due to improper packaging.
          <br />
          <br />
          m. The Merchant shall ensure that the information provided to Vyloo
          for listing the products on the Platform is accurate and up to date.
          The Merchant shall be liable for any claims or disputes raised by the
          Buyers for incorrect or misleading information provided on the
          Platform. <br />
          <br />
          n. The Merchant shall ensure that the Orders are prepared and handed
          over to the PDPs in accordance with the delivery time slots chosen by
          the Buyers. The Merchant shall be liable for any claims or disputes
          raised by the Buyers for delayed delivery of Orders due to the
          Merchant’s failure to prepare the Orders in time. <br />
          <br />
          o. Vyloo reserves the right to: 1. Delist products temporarily
          discontinue or terminate the Services with immediate effect for any
          breach or non-compliance by the Merchant which includes, but is not
          limited to, the following instances: <br />
          - Buyer complaints received by Vyloo which are directly or indirectly
          attributable to the quality of product sold by the Merchant. <br />
          - Breach of the provisions of the following acts: - Information
          Technology Act, 2000 and the rules thereunder. <br />
          - Food Safety and Standards Act, 2006 and the rules and regulations
          thereunder. <br />
          - Legal Metrology Act, 2009 and the rules thereunder. <br />
          - Drugs and Cosmetics Act, 1940 and the Rules thereunder. <br />
          - The Pharmacy Act 1948 and the Rules thereunder <br />
          - GCP guidelines <br />
          - The Narcotic Drugs and Psychotropic Substances Act, 1985 <br />
          - CDSCO and the Rules and Guidelines thereunder <br />- Drugs And
          Magic Remedies (Objectionable Advertisements) Act, 1954. <br />
          - Any other applicable domestic laws, rules, and regulations
          (including the provisions of any applicable exchange control laws or
          regulations in force). <br />
          - International laws, foreign exchange laws, statutes, ordinances, and
          regulations (including, but not limited to goods and service tax
          and/or any other taxes, custom duty, local levies), as may be
          applicable, to Merchant.
          <br />
          - Misbehaviour with Vyloo’s representatives/personnel.
          <br />- Inclusion of marketing or promotional material belonging to
          competitors in Orders delivered by PDPs. <br />
          - Any other breach of the terms of the Merchant T&C. <br />
          2. Resume provision of Platform Services to the Merchant at their own
          discretion. <br />
          3. Immediately delist any product on the Platform, which is not in
          compliance with the Applicable Laws. <br />
          4. Services offered by Vyloo on the Vyloo Platform are non-exclusive
          in nature. <br />
          5. Vyloo reserves the right to introduce, withdraw, or modify any
          category and attach necessary conditions thereto on its Platform as it
          may deem fit from time to time.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Your Account and Registration Obligations:
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          ● If you use the Merchant interface or the Platform, you shall be
          responsible for maintaining the confidentiality of your login ID and
          password details and all activities that occur with the use of your
          login and password details. <br />
          ● If you provide any information or content required under these terms
          and conditions to be put up on the Platform, which is untrue,
          inaccurate, not updated, or incomplete or we have reasonable grounds
          to suspect that such information is untrue, inaccurate, not updated,
          incomplete, or not in accordance with the Merchant T&C, we shall have
          the right to indefinitely suspend, terminate, or block you from
          availing Services.
          <br />● Your registration with Vyloo as a Merchant is exclusively for
          your use to gain access to the Platform and is strictly not
          transferable.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Communications:
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - When you use the Merchant interface/Platform or send emails, other
          data, information, or communication to us, you agree and understand
          that you are communicating with us through electronic records and you
          consent to receive communications via electronic records from us
          periodically or as and when required. - We may communicate with you by
          email or any other mode of communication, electronic or otherwise.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Vyloo Partner App and Use:
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - Merchant shall be granted access to the Merchant interface for
          handheld devices and at its sole discretion. Vyloo may issue a
          handheld device to the Merchant (with embedded partner App) and the
          Merchant shall: <br />- Ensure it has a functional electronic
          tablet/mobile phone with the Vyloo partner App installed and up to
          date at all times to (a) receive and confirm Buyer Orders and provide
          estimates of delivery times or; (b) reject the Order through the
          Merchant interface.
          <br />
          - In case it declines Orders through the electronic tablet/mobile
          phones provided however, then it shall not use alternate means
          (phone/emails) to connect with the Buyer in parallel to take Orders
          and thereby circumvent the process. <br />- Communicate with Vyloo
          service operator through the phone call and/or SMS. <br />- Manage
          real-time status of item stock/inventories and outlet operation time.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">Disclaimer:</h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - Platform Services are provided on best effort basis and Vyloo shall
          have no liability for failure of such services for the reasons beyond
          its control.
          <br />
          - To the fullest extent permitted by law, Vyloo and its affiliates,
          and each of their respective officers, directors, members, employees,
          and agents disclaim all warranties, express or implied, in connection
          with these Merchant T&C, the Platform Services, and any use thereof,
          including, without limitation, the implied warranties of its
          merchantability, fitness for a particular purpose, and
          non-infringement.
          <br />
          - Vyloo makes no warranties or representations about the accuracy or
          completeness of the Platform’s content or the content of any other
          websites linked to the Platform, and assumes no liability or
          responsibility for any (a) errors, mistakes, or inaccuracies of
          content and materials, (b) personal injury or property damage, of any
          nature whatsoever, resulting from the Merchant’s access to and use of
          the Platform and the Platform Services, (c) any unauthorized access to
          or use of Vyloo servers and/or any and all personal information and/or
          financial information stored therein, (d) any interruption or
          cessation of transmission to or from the Platform or otherwise with
          respect to the Services, (e) any bugs, viruses, trojan horses, or the
          like which may be transmitted to or through the Platform or services
          by any third party, and/or (f) any errors or omissions in any content
          and materials or for any loss or damage of any kind incurred as a
          result of the use of any content posted, transmitted, or otherwise
          made available via the Platform or Platform Services. <br />- Vyloo
          disclaims any and all liability for direct, indirect, incidental,
          special, consequential, punitive or other similar damages that may
          arise due to any deficiency in the Orders attributable to the
          Merchant, any malfunction or error of the Platform by the Merchant
          including but not limited to, technical errors, delays, omissions,
          inaccuracies in the content provided.
        </p>
        <br />

        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Intellectual Property:
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - Each Party owns all right, title, and interest in their respective
          trade names, service marks, inventions, copyrights, trade secrets,
          patents, and other intellectual property (“Intellectual Property”).
          These Merchant T&C do not constitute a license or a right to use any
          Party’s Intellectual Property other than as expressly set out herein.
          - In the event of any third-party claims infringement of its
          intellectual property rights on the Platform, and such content which
          is alleged to have infringed such third party intellectual property
          rights has been shared on the Platform by you, it shall be your
          responsibility to investigate, defend, settle, and discharge any such
          intellectual property infringement claim in relation to such content
          on the Platform.
          <br />
          - You recognize that Vyloo Technologies Private Limited is the
          registered owner of the word mark, ‘Vyloo’ and the logo ‘Vyloo’
          including but not limited to its variants (‘Licensed Marks’) and
          further agree not to directly or indirectly, attack or assist another
          in attacking the validity of Vyloo’s or its affiliates' proprietary
          rights in, the Licensed Marks or any registrations thereof, or file
          any applications for the registration of the Licensed Marks or any
          names or logos derived from or confusingly similar to the Licensed
          Marks, any variation thereof, or any translation or transliteration
          thereof in another language, in respect of any products/services and
          in any territory throughout the world. In the event you notice any
          infringements of Licensed Marks, please write to us at
          support@vyloo.in.
          <br />- Vyloo does not and cannot verify that Merchants have the right
          or ability to sell or distribute their listed products. However, Vyloo
          is committed to ensuring that item listings do not infringe upon
          intellectual property rights of third parties once an authorized
          representative of the rights owner properly reports them to Vyloo.
        </p>
        <br />

        <h1 className="text-2xl font-black text-[#16496F] py-1">Notice:</h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - All notices under these Merchant T&C shall be sent by registered
          post acknowledgment due, contemporaneous courier or email or hand
          delivered to the address mentioned below: 10251, Gali Mill Wali,
          Library Road, Azad Market, Delhi, between 9.30 am to 5.30 pm from
          Monday to Friday.
          <br />
          - If the Merchant notices any discrepancy in the Settlement of
          Collections, the Merchant may raise a ticket by writing an email to
          support@vyloo.in and the same will be mutually resolved by both
          parties within 15 days from the date on which the ticket was raised.
          <br />- Vyloo shall issue notice to Merchant via registered post
          acknowledgment due, contemporaneous courier, hand delivery, partner
          App, or email or mobile number registered with Vyloo.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Force Majeure:
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          - If at any time during the currency of the agreement between Merchant
          and Vyloo, the performance in whole or in part by either Party or any
          obligations under the contract shall be prevented or delayed by
          reasons of acts of God, war, hostilities, invasions, act of public
          enemies, civil commotion, sabotage, fire, explosions, flood, cyclone,
          earthquake, epidemics and any form of government restrictions on
          business operations, adverse climatic conditions and other unforeseen
          events or circumstances etc. (hereinafter referred to as “Force
          Majeure Conditions”) which directly affect the performance of
          obligations and which are beyond the reasonable control of either
          party, provided notice of the happening of any one or more of the
          Force Majeure Conditions is given by either party to the other within
          21 days from the date of from the date of the occurrence of the Force
          Majeure Conditions, the obligation of the party giving notice shall
          stand suspended without any liability towards the other party so long
          as such Force Majeure Conditions continues except such obligations as
          may have accrued till the date of commencement of the Force Majeure.
        </p>
        <br />
        <h1 className="text-2xl font-black text-[#16496F] py-1">
          Anti Bribery
        </h1>
        <hr className="w-auto py-2" />
        <p className="py-2 text-md text-[#1c1e21ad]">
          In case the Parties become aware of any benefit in cash or in kind
          being provided to any officer or employee, or any relative / associate
          of any officer or employee, of the other Party or of any of its
          associate companies, in Order to secure this transaction, it shall
          notify the other Party of the same and the Parties will determine the
          way forward. In case of notification to Vyloo, it shall be sent to
          details captured below: i. Email: By sending an email to “
          grievanceofficer@vylootechnologies.in ” ii. Mob. no. - “
          +91-9315774947 ”
        </p>
        <br />
      </div>
    </>
  );
}
