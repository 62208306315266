import React, { useState } from 'react';
import Logo2 from '../assets/logo2.svg';

const MNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`text-[#16496F]  lg:py-8 p-8`}>
      <div className="container mx-auto flex justify-between items-center">
        <a href="/">
          <div className="flex items-center space-x-4">
            <img src={Logo2} className="h-6 w-auto" alt="Vyloo Logo" />
          </div>
        </a>
        <div className="hidden md:flex items-center space-x-16">
          {/* <a href="/" className=" hover:text-[#089DD5] font-normal">
            Features
          </a> */}

          <a href="/" className="hover:text-[#089DD5] font-normal">
            Vyloo for Customers
          </a>
          <a
            href="/merchant-terms-and-conditions"
            className=" hover:text-[#089DD5] font-normal"
          >
            Merchant Terms & Conditions
          </a>
          <a href="/privacy" className=" hover:text-[#089DD5] font-normal">
            Privacy Policy
          </a>
          <a href="/contact" className=" hover:text-[#089DD5] font-normal">
            Contact
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.vylooTech.vyloo_seller&hl=en_US"
            className=" bg-[#16496F] transition duration-300 ease-in-out hover:bg-[#089DD5] font-normal text-white p-4 px-6 rounded-full"
          >
            Download
          </a>
        </div>

        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className={`text-#16496F`}>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'
                }
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Overlay menu for mobile */}
      {isMenuOpen && (
        <div className={`md:hidden bg-[#ffffff] p-4`}>
          <div className="flex flex-col space-y-4">
            {/* <a href="/" className=" hover:text-gray-300 font-normal">
            Features
          </a>
          <a href="/" className=" hover:text-gray-300 font-normal">
          Team
          </a> */}
            <a href="/" className="hover:text-gray-300 font-normal">
              Vyloo for Customers
            </a>
            <a
              href="/merchant-terms-and-conditions"
              className=" hover:text-[#089DD5] font-normal"
            >
              Merchant Terms & Conditions
            </a>
            <a href="/privacy" className=" hover:text-gray-300 font-normal">
              Privacy Policy
            </a>
            <a href="/contact" className=" hover:text-gray-300 font-normal">
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default MNavbar;
