import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import CreateAdmin from './CreateAdmin';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AdminNav from './AdminNav';

function Dashboard() {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const getAdminRole = sessionStorage.getItem('adminRole');
  const getAdminName = sessionStorage.getItem('adminName');
  const [filterKey, setFilterKey] = useState(getAdminRole);
  const { logout } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openNotificationModal = () => setIsNotificationModalOpen(true);
  const closeNotificationModal = () => setIsNotificationModalOpen(false);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      setLoading(true);
      setError('');
      try {
        const token = sessionStorage.getItem('authToken');

        const response = await axios.post(
          `https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/superadmin/getAllAdmins?filterKey=${filterKey}`,
          {},
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 401) {
          setAdmins([]);
          setLoading(false);
          return;
        }

        const adminNames = response.data.data.admins.map((admin) => ({
          id: admin._id,
          name: admin.name,
          role: admin.role,
        }));
        setAdmins(adminNames);
      } catch (error) {
        setError('Failed to fetch admin details.');
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, [filterKey]);

  const deleteAdmin = async (adminId) => {
    const authToken = sessionStorage.getItem('authToken');

    try {
      const response = await axios.delete(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/superadmin/deleteAdmin',
        {
          data: { adminId },
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Admin deleted successfully!');
        setAdmins((prevAdmins) =>
          prevAdmins.filter((admin) => admin.id !== adminId)
        );
      } else {
        toast.error('Failed to delete admin!');
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setError(
        'An error occurred while trying to delete admin. Please try again.'
      );
    }
  };

  const sendPushNotification = async () => {
    const authToken = sessionStorage.getItem('authToken');

    try {
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/masteradmin/sendPromotionalPushNotification',
        {
          messageTitle: notificationTitle,
          messageBody: notificationBody,
        },
        {
          headers: {
            Authorization: `${authToken}`,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        closeNotificationModal();
        setNotificationTitle('');
        setNotificationBody('');
      } else {
        toast.error('Failed to send notification!');
      }
    } catch (error) {
      toast.error('An error occurred while sending notification.');
    }
  };

  const navigateToSupportRequests = () => {
    navigate('/admin/support-requests');
  };

  const navigateToCategories = () => {
    navigate('/admin/categories');
  };

  const navigateToMedicines = () => {
    navigate('/admin/medicines');
  };

  const navigateToOTC = () => {
    navigate('/admin/otc');
  };

  const navigateToOnlineMerchants = () => {
    navigate('/admin/merchants-online');
  };

  const navigateToOnlineDeliveryPartners = () => {
    navigate('/admin/delivery-partners-online');
  };

  const navigateToSplitPayments = () => {
    navigate('/admin/split-settlement-details');
  };

  return (
    <>
      <AdminNav getAdminName={getAdminName} logout={logout} />
      <div className="p-6">
        <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
        {getAdminRole === 'superadmin' && (
          <>
            <button
              onClick={openModal}
              className="px-4 py-2 mr-4 font-bold text-white bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline"
            >
              Add Admin
            </button>
          </>
        )}

        {getAdminRole === 'masteradmin' && (
          <>
            <button
              onClick={openNotificationModal}
              className="px-4 py-2 mr-4 font-bold text-white bg-purple-500 rounded-full hover:bg-purple-700 focus:outline-none focus:shadow-outline"
            >
              Send Push Notification
            </button>
          </>
        )}
        {isNotificationModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">
                Send Push Notification
              </h2>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Title</label>
                <input
                  type="text"
                  value={notificationTitle}
                  onChange={(e) => setNotificationTitle(e.target.value)}
                  className="w-full px-4 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-bold mb-2">Body</label>
                <textarea
                  value={notificationBody}
                  onChange={(e) => setNotificationBody(e.target.value)}
                  className="w-full px-4 py-2 border rounded"
                  rows="4"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={sendPushNotification}
                  className="px-4 py-2 mr-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
                >
                  Ok
                </button>
                <button
                  onClick={closeNotificationModal}
                  className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <CreateAdmin isOpen={isModalOpen} onClose={closeModal} />
        <button
          onClick={navigateToSupportRequests}
          className="px-4 py-2 mt-4 font-bold mr-4 text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          View Support Requests
        </button>
        <button
          onClick={() => navigate('/admin/order-status')}
          className="px-4 py-2 mt-4 font-bold mr-4 text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          Order Status
        </button>
        <button
          onClick={navigateToSplitPayments}
          className="px-4 py-2 mt-4 font-bold mr-4 text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          Split Settlement Details
        </button>

        <button
          onClick={navigateToCategories}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mb-4"
        >
          Categories
        </button>
        <br />
        <button
          onClick={navigateToOnlineMerchants}
          className="bg-blue-500 hover:bg-blue-700 mr-4 text-white font-bold py-2 px-4 rounded-full mb-4"
        >
          Merchants Online
        </button>
        <button
          onClick={navigateToOnlineDeliveryPartners}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mb-4"
        >
          Delivery Partners Online
        </button>
        <h1 className="text-2xl font-bold mb-4">All Products</h1>

        <button
          onClick={navigateToMedicines}
          className="bg-blue-500 mr-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mb-4"
        >
          Medicines
        </button>
        <button
          onClick={navigateToOTC}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mb-4"
        >
          OTC
        </button>

        {getAdminRole === 'superadmin' ||
          (getAdminRole === 'masteradmin' && (
            <>
              <div className="mb-4">
                <select
                  value={filterKey}
                  onChange={(e) => setFilterKey(e.target.value)}
                  className="mt-4 px-4 py-2 font-bold text-white bg-blue-500 rounded-full focus:outline-none focus:shadow-outline"
                >
                  <option value="admin">Admin</option>
                  <option value="superadmin">Superadmin</option>
                  <option value="all">All</option>
                </select>
              </div>

              {loading && <p>Loading...</p>}
              {error && <p className="text-red-500">{error}</p>}

              <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                  <thead>
                    <tr className="w-full bg-gray-800 text-white">
                      <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                        Name
                      </th>
                      <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                        Role
                      </th>
                      <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-700">
                    {admins.length > 0 ? (
                      admins.map((admin) => (
                        <tr key={admin.id} className="hover:bg-gray-100">
                          <td className="py-3 px-4">{admin.name}</td>
                          <td className="py-3 px-4">{admin.role}</td>
                          <td className="py-3 px-4">
                            {admin.role !== 'superadmin' &&
                              admin.role !== 'masteradmin' && (
                                <button
                                  onClick={() => deleteAdmin(admin.id)}
                                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                                >
                                  Delete
                                </button>
                              )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="py-3 px-4" colSpan="3">
                          No admins found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ))}
      </div>
    </>
  );
}

export default Dashboard;
