import React from 'react';
import success from '../assets/success.png';
import Logo from '../assets/Logo3.png';

export default function SuccessP() {
  return (
    <>
      <div className="mt-64 mb-64 text-center">
        <img
          className="text-center inline-block w-24"
          src={success}
          alt="success_img"
        />
        <br />
        <br />
        <p1 className="text-[#089DD5] font-black text-xl">
          Payment Successfull
        </p1>{' '}
        <br />
        <br />
        <img className="text-center inline-block w-24" src={Logo} alt="logo" />
      </div>
    </>
  );
}
