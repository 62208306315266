import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';
import m1 from '../assets/1.webp';
import Navbar from '../components/navbar';
import img from '../assets/banner.webp';

export default function Download() {
  useEffect(() => {
    // Define API URL for incrementing visiting number
    const apiUrl =
      'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/incrementVisitingNumberOnQrScan';

    // Call the API when the component is mounted
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log(data.message); // Log the success message
        } else {
          console.log('Failed to increment visiting number');
        }
      })
      .catch((error) => {
        console.error('Error while incrementing visiting number:', error);
      });

    const iosAppStoreUrl = 'https://apps.apple.com/in/app/vyloo/id6596771525';
    const androidPlayStoreUrl =
      'https://play.google.com/store/apps/details?id=com.vylooTech.vyloo&hl=en_IN';

    if (isIOS) {
      window.location.href = iosAppStoreUrl;
    } else if (isAndroid) {
      window.location.href = androidPlayStoreUrl;
    } else {
      console.log('Unsupported platform');
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className="p-2 lg:p-8">
        <img src={img} className="rounded-xl w-[full]" alt="vyloo logo" />
      </div>
      <div
        className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between bg-white px-4 py-0 lg:px-24 lg:py-12 rounded-lg shadow-lg"
        id="download"
      >
        <div className="md:w-1/2 mt-4 mb-4 md:mb-0 md:mr-4">
          <h2 className="text-2xl font-black mb-2 text-[#16496F]">
            Get your medicines delivered within{' '}
            <span className="text-[#089DD5]">15 minutes</span>
          </h2>
          <p className="text-gray-500 mb-4 text-lg py-4">
            Vyloo Technologies Pvt. Ltd. is a software development company. We
            at Vyloo Technologies are developing a mobile Application VYLOO
            which will be India's fastest medicine delivery offering 15 min
            medicine delivery.
          </p>

          <div className="flex space-x-8">
            <button class="bg-[#16496F] transition duration-300 ease-in-out hover:bg-[#089DD5] text-white font-semibold  py-2 px-4 rounded-md  inline-flex items-center">
              <FaAppStore className="w-8 h-8" />
              <span className="text-[0.8rem] text-left pl-4">
                Download on <br />{' '}
                <span className="text-sm lg:text-[1.2rem]">App Store</span>
              </span>
            </button>
            <a href="https://play.google.com/store/apps/details?id=com.vylooTech.vyloo&hl=en_IN">
              <button class="bg-[#16496F] transition duration-300 ease-in-out hover:bg-[#089DD5] text-white font-semibold  py-2 px-4 rounded-md  inline-flex items-center">
                <FaGooglePlay className="w-7 h-7" />
                <span className="text-[0.8rem] text-left pl-4">
                  Get it on
                  <br />{' '}
                  <span className=" text-sm lg:text-[1.2rem]">Google Play</span>
                </span>
              </button>
            </a>
          </div>
        </div>

        <div className="md:w-1/4 mr-28 hidden md:block">
          <img src={m1} alt="iPhone Mockup" className="w-full" />
        </div>
      </div>
    </>
  );
}
