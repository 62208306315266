import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const ConfirmationModal = ({ show, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold mb-4">Confirm Refund</h2>
        <p>Are you sure you want to refund this order?</p>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const UserOrderHistory = () => {
  const { id } = useParams(); // Extract userId from URL params
  const [orderHistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    const fetchUserOrderHistory = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');

        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getUserOrderHistory',
          {
            userId: id,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setOrderHistory(response.data.data.orderHistory);
        } else {
          setError('Failed to fetch user order history.');
        }
      } catch (error) {
        setError('An error occurred while fetching user order history.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserOrderHistory();
  }, [id]);

  const handleRefundClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowModal(true);
  };

  const handleConfirmRefund = async () => {
    setShowModal(false);

    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/changeOrderStatusToRefund',
        { orderId: selectedOrderId },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Order status changed to refund successfully!');
        setOrderHistory((prevOrders) =>
          prevOrders.map((order) =>
            order._id === selectedOrderId
              ? { ...order, status: 'refunded' }
              : order
          )
        );
      } else {
        toast.error('Failed to change order status to refund.');
      }
    } catch (error) {
      toast.error('An error occurred while processing the refund.');
    }
  };

  const handleCancelRefund = () => {
    setShowModal(false);
    setSelectedOrderId(null);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (orderHistory.length === 0) return <p>No order history found.</p>;

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">User Order History</h1>

      {orderHistory.length > 0 ? (
        orderHistory.map((order) => (
          <div
            key={order._id}
            className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200"
          >
            <h2 className="text-xl font-semibold mb-4">
              Order ID: {order.orderId}
            </h2>
            <div className="mb-4">
              <p>
                <strong>Order Placed Time:</strong>{' '}
                {new Date(order.orderPlacedTime).toLocaleString()}
              </p>
              <p>
                <strong>Status:</strong> {order.status}
              </p>
              <p>
                <strong>Payment Method:</strong> {order.paymentMethod}
              </p>
              <p>
                <strong>Total Amount:</strong> ₹{order.totalAmount}
              </p>
              <p>
                <strong>Grand Total:</strong> ₹{order.grandTotal}
              </p>
              <p>
                {' '}
                <strong>_id:</strong> {order._id}
              </p>
            </div>
            <div className="border-t border-gray-300 pt-4">
              <h3 className="text-lg font-semibold mb-2">Order Items:</h3>
              {order.orderItems.map((item) => (
                <div
                  key={item._id}
                  className="flex mb-4 border-b border-gray-300 pb-4"
                >
                  <img
                    src={`https://d2l55457kk8x11.cloudfront.net/${item.displayImagesUrl}`}
                    alt={item.displayName}
                    className="w-24 h-24 object-cover mr-4"
                  />
                  <div>
                    <p>
                      <strong>Product:</strong> {item.displayName}
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item.quantity}
                    </p>
                    <p>
                      <strong>Price:</strong> ₹{item.price}
                    </p>
                    <p>
                      <strong>Selling Price:</strong> ₹{item.sellingPrice}
                    </p>
                    <p>
                      <strong>Packaging:</strong> {item.packaging}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              {order.status === 'refunded' ? (
                <p className="text-green-500 font-semibold">
                  Amount already refunded
                </p>
              ) : (
                <button
                  onClick={() => handleRefundClick(order._id)}
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                >
                  Refund Order
                </button>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No orders found.</p>
      )}

      <ConfirmationModal
        show={showModal}
        onConfirm={handleConfirmRefund}
        onCancel={handleCancelRefund}
      />
    </div>
  );
};

export default UserOrderHistory;
