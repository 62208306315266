import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Products = () => {
  const { subCategoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    const productType = localStorage.getItem('productType');

    const fetchProducts = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getProductListOfSubCategory',
          {
            page, // <-- Use dynamic page state here
            size: 12, // Adjust size if needed
            subCategoryId,
            productType,
            query: searchQuery,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setProducts(response.data.data.products[0].products);
        } else {
          setError('Failed to fetch products.');
        }
      } catch (error) {
        setError('An error occurred while fetching products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [subCategoryId, page, searchQuery]); // Ensure `page` is a dependency to re-fetch products on page change

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === 'previous' && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setPage(1); // Reset to page 1 when searching
  };

  const handleDeleteProduct = async (productId) => {
    const productType = localStorage.getItem('productType');
    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/removeProductFromSubCategory',
        {
          productId,
          subCategoryId,
          productType,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (response.data.success) {
        setProducts(products.filter((product) => product._id !== productId));
        alert('Product deleted successfully');
      } else {
        setError('Failed to delete product.');
      }
    } catch (error) {
      setError('An error occurred while deleting the product.');
    }
  };

  return (
    <div className="p-6 bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">Products</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search for otc products..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="p-2 border border-gray-300 rounded w-full"
        />
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {products.map((product) => (
              <div
                key={product._id}
                className="bg-white shadow-md rounded-lg p-4"
              >
                <h3 className="text-lg font-bold">{product.displayName}</h3>
                <p className="text-gray-700 mt-2">MRP: ₹{product.mrp}</p>
                <p className="text-gray-700 mt-2">{product.packaging}</p>
                <p className="text-gray-700 mt-2">
                  Manufacturer: {product.manufacturer}
                </p>
                {product.imageUrl && (
                  <img
                    src={`https://d2l55457kk8x11.cloudfront.net/${product.imageUrl}`}
                    alt={product.displayName}
                    className="mt-4 w-24 rounded-lg"
                  />
                )}
                <button
                  className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
                  onClick={() => handleDeleteProduct(product._id)}
                >
                  Delete Product
                </button>
              </div>
            ))}
          </div>
          <div className="flex justify-center space-x-4 mt-4">
            <button
              onClick={() => handlePageChange('previous')}
              className={`px-4 py-2 rounded ${
                page === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'
              }`}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className="px-4 py-2 rounded bg-gray-200">{page}</span>
            <button
              onClick={() => handlePageChange('next')}
              className="px-4 py-2 rounded bg-blue-500 text-white"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
