import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../../AuthContext'; // Ensure the correct path
import toast from 'react-hot-toast';

const SupportRequests = () => {
  const [supportRequests, setSupportRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSupportRequests = async () => {
      setLoading(true);
      setError('');

      try {
        const token = sessionStorage.getItem('authToken');
        console.log(token);
        const response = await axios.post(
          'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/getAllSupportRequests',
          {
            filterKey: 'all',
            page: 1,
            size: 100,
          },
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.data.success) {
          setSupportRequests(response.data.data.allRequests);
        } else {
          setError('Failed to fetch support requests.');
        }
      } catch (error) {
        setError('An error occurred while fetching support requests.');
      } finally {
        setLoading(false);
      }
    };

    fetchSupportRequests();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-4">Support Requests</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Ticket ID
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Order ID
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Status
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Receiver Name
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {supportRequests.length > 0 ? (
              supportRequests.map((request) => (
                <tr key={request._id} className="hover:bg-gray-100">
                  <td className="py-3 px-4">{request.ticketId}</td>
                  <td className="py-3 px-4">{request.orderId}</td>
                  <td className="py-3 px-4">{request.status}</td>
                  <td className="py-3 px-4">{request.receiverName}</td>
                  <td>
                    <Link
                      to={`/admin/customer-request/${request._id}`}
                      className="text-blue-500 hover:underline"
                    >
                      <button className="mt-2 px-4 py-2 mr-4 font-bold text-white bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline">
                        View Customer Request Details
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="py-3 px-4" colSpan="5">
                  No support requests found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupportRequests;
