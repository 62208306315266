import React from 'react';
import Pharmacist from '../assets/pharmacist.webp';

export default function Customers() {
  return (
    <>
      <div className="flex flex-col md:flex-row items-center md:items-start justify-center md:justify-between bg-[#F5F7FA] px-4 py-0 lg:px-24 lg:py-12 rounded-lg ">
        <div className="block md:hidden md:w-1/3 mr-28">
          <img src={Pharmacist} alt="vector" className="w-full" />
        </div>
        <div className="md:w-1/2 mt-0 md:mt-12 mb-4 md:mb-0 md:mr-4">
          <h2 className="text-3xl font-black mb-2 text-[#16496F]">
            Are you a seller ? Get
            <span className="text-[#089DD5]"> Vyloo for Merchants</span>
          </h2>
          <p className="text-gray-500 mb-4 text-lg py-4">
            Register your business on Vyloo Merchant App and take your shop
            online. With Vyloo, you can sell & manage orders efficiently, on an
            online platform and reach a wider audience.
          </p>

          <a href="/contact">
            <button className="bg-[#089DD5] p-4 rounded-lg text-white  transition duration-300 ease-in-out hover:bg-[#16496F] mb-6 md:mb-0">
              Learn More
            </button>
          </a>
        </div>

        <div className="hidden md:block md:w-1/3 mr-28">
          <img src={Pharmacist} alt="vector" className="w-full" />
        </div>
      </div>
    </>
  );
}
