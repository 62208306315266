import React from 'react';
import { useEffect } from 'react';

export default function DecathUrl() {
  const decathUrl =
    'https://play.decathlon.in/event-details/Fitness-Campaign---Vyloo/ad122b84-6161-11ef-b2cc-03e9b24afede?utm_source=sharebutton&utm_medium=decathlon_play_partner_app&utm_campaign=externalshare&fbclid=PAZXh0bgNhZW0CMTEAAabDefV7zQTQoxt7euMW7GSl7pzj8LbDQ7-U_vhEDmD1I-HrGD4xUdr71Ds_aem_bDwf_1Hpix-Ored1JOg0-Q';
  useEffect(() => {
    window.location.href = decathUrl;
  });

  return (
    <>
      <h1>Redirecting to Decathlon URL</h1>
    </>
  );
}
