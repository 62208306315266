import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AdminNav from './AdminNav';
import { useAuth } from '../../AuthContext';
import toast, { Toaster } from 'react-hot-toast';
import notificationSound from '../../assets/audio.mp3';

const OrderStatus = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState(
    () => sessionStorage.getItem('orderFilter') || 'all'
  );
  const [page, setPage] = useState(1);
  const [size] = useState(5);
  const [selectedOrder, setSelectedOrder] = useState(null); // Track selected order for cancellation
  const [cancelReason, setCancelReason] = useState(''); // Track the selected reason
  const previousPendingCountRef = useRef(0); // Track the previous number of pending orders
  const { logout } = useAuth();
  const getAdminName = sessionStorage.getItem('adminName');
  const audio = new Audio(notificationSound);
  const fetchOrders = async (
    status = 'all',
    page = 1,
    size = 5,
    showToast = false
  ) => {
    setLoading(true);
    setError('');

    const fetchPromise = axios.post(
      'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/allOrderDetails',
      {
        filterKey: status,
        page,
        size,
      },
      {
        headers: {
          Authorization: `${sessionStorage.getItem('authToken')}`,
        },
      }
    );

    const fetchOrdersPromise = showToast
      ? toast.promise(fetchPromise, {
          loading: 'Refreshing orders...',
          success: 'Orders refreshed!',
          error: 'Failed to refresh orders.',
        })
      : fetchPromise;

    fetchOrdersPromise
      .then((response) => {
        if (response.data.success) {
          setOrders(response.data.data.orders);
          setFilteredOrders(response.data.data.orders);

          if (status === 'pending') {
            const newPendingCount = response.data.data.orders?.length ?? 0;

            // Check if there are new pending orders

            console.log(newPendingCount);
            console.log(previousPendingCountRef.current);
            if (newPendingCount > previousPendingCountRef.current) {
              audio.play();
              console.log('pending notif sound');
            } else if (newPendingCount < previousPendingCountRef.current) {
              audio.pause();
              console.log('pending notif sound paused');
            }

            // Update the previous pending count
            previousPendingCountRef.current = newPendingCount;
          }
        } else {
          setError('Failed to fetch order details.');
        }
      })
      .catch(() => {
        setError('An error occurred while fetching order details.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders(filter, page, size, true);
    if (filter === 'pending') {
      const intervalId = setInterval(() => {
        fetchOrders(filter, page, size, true);
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [filter, page, size]);

  const handleFilterChange = (status) => {
    setFilter(status);
    setPage(1);
    sessionStorage.setItem('orderFilter', status);
    fetchOrders(status, 1, size, false);
  };

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === 'previous' && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleCancelOrder = async () => {
    if (!selectedOrder || !cancelReason) return;

    try {
      const response = await axios.post(
        'https://o4onq0lc89.execute-api.ap-south-1.amazonaws.com/admin/admin/cancelOrderFromAdmin',
        {
          orderId: selectedOrder._id,
          cancelledReason: cancelReason,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.data.success) {
        toast.success('Order cancelled successfully.');
        fetchOrders(filter, page, size, false);
        setSelectedOrder(null);
        setCancelReason('');
      } else {
        toast.error('Failed to cancel the order.');
      }
    } catch (error) {
      toast.error('An error occurred while cancelling the order.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  const timeConversion = (currentDate) => {
    if (!currentDate || isNaN(new Date(currentDate).getTime())) return null;

    let hours = currentDate.getUTCHours();
    let minutes = currentDate.getUTCMinutes().toString();

    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }

    let period = hours >= 12 ? 'PM' : 'AM';
    if (hours === 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
    }

    const date = currentDate.getUTCDate();
    const month = currentDate.getUTCMonth() + 1;
    const year = currentDate.getUTCFullYear();

    return `${date}/${month}/${year} ${hours}:${minutes} ${period}`;
  };

  return (
    <>
      <AdminNav getAdminName={getAdminName} logout={logout} />
      <div className="p-6 bg-gray-100 min-h-screen">
        <Toaster />
        <h1 className="text-3xl font-bold mb-6">Order Status</h1>
        <div className="mb-6 flex space-x-4">
          <button
            className={`px-4 py-2 rounded ${
              filter === 'all'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 text-black'
            }`}
            onClick={() => handleFilterChange('all')}
          >
            All
          </button>
          <button
            className={`px-4 py-2 rounded ${
              filter === 'pending'
                ? 'bg-yellow-500 text-white'
                : 'bg-gray-300 text-black'
            }`}
            onClick={() => handleFilterChange('pending')}
          >
            Pending
          </button>
          <button
            className={`px-4 py-2 rounded ${
              filter === 'confirmed'
                ? 'bg-green-500 text-white'
                : 'bg-gray-300 text-black'
            }`}
            onClick={() => handleFilterChange('confirmed')}
          >
            Confirmed
          </button>
          <button
            className={`px-4 py-2 rounded ${
              filter === 'delivered'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 text-black'
            }`}
            onClick={() => handleFilterChange('delivered')}
          >
            Delivered
          </button>
          <button
            className={`px-4 py-2 rounded ${
              filter === 'refunded'
                ? 'bg-red-500 text-white'
                : 'bg-gray-300 text-black'
            }`}
            onClick={() => handleFilterChange('refunded')}
          >
            Refunded
          </button>
        </div>

        {filteredOrders.length === 0 ? (
          <div className="text-center text-gray-500">
            <p>No orders with status '{filter}' are present.</p>
          </div>
        ) : (
          <div>
            {filteredOrders.map((order) => (
              <div
                key={order._id}
                className="bg-white shadow-md rounded-lg p-4 mb-6 border border-gray-200"
              >
                <h2 className="text-xl font-semibold mb-4">
                  Order ID: {order.orderId}
                </h2>
                <p className="mb-4">Split ID: {order._id}</p>

                {order.status === 'pending' || order.status === 'confirmed' ? (
                  <div>
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded"
                      onClick={() => setSelectedOrder(order)}
                    >
                      Cancel Order
                    </button>
                  </div>
                ) : null}

                {selectedOrder && selectedOrder._id === order._id && (
                  <div className="mt-4">
                    <select
                      className="border border-gray-300 rounded px-3 py-2 w-full"
                      value={cancelReason}
                      onChange={(e) => setCancelReason(e.target.value)}
                    >
                      <option value="">Select Cancellation Reason</option>
                      <option value="due to customer">Due to Customer</option>
                      <option value="due to merchant">Due to Merchant</option>
                      <option value="due to vyloo">Due to Vyloo</option>
                    </select>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
                      onClick={handleCancelOrder}
                    >
                      OK
                    </button>
                  </div>
                )}

                <h2 className="text-xl font-semibold">
                  Merchant Name : {order.merchantShopName}
                </h2>
                <p>Merchant Id: {order.merchantId}</p>
                <p>
                  Merchant Address: {order.merchantAddress1},{' '}
                  {order.merchantAddress2}
                </p>
                <p className="mb-4">
                  Merchant Phone number : {order.merchantMobileNumber}
                </p>
                <h2 className="text-xl font-semibold">
                  Delivery Partner ID: {order.deliveryPartnerId}
                </h2>
                <p>Delivery Partner Name: {order.deliveryPartnerName}</p>
                <p className="mb-4">
                  Delivery Partner Number : {order.deliveryPartnerMobileNumber}
                </p>

                <h2 className="text-xl font-semibold">
                  User ID: {order.deliveryPartnerId}
                </h2>
                <p>User Name: {order.userName}</p>
                <p>User Number : {order.userMobileNumber}</p>
                <p>
                  User Address: {order.userAddress1}, {order.userAddress2}
                </p>
                <br />
                {/* Displaying Ordered Items */}
                <div className="mb-4">
                  <h2 className="text-lg font-semibold">Ordered Items:</h2>
                  {order.orderItems.map((item) => (
                    <div key={item.productId} className="mb-2">
                      <p>
                        <strong>Product:</strong> {item.displayName} (
                        {item.packaging})
                      </p>
                      <p>
                        <strong>Quantity:</strong> {item.quantity}
                      </p>
                      <p>
                        <strong>Price:</strong> ₹{item.price}
                      </p>
                      <img
                        src={`https://d2l55457kk8x11.cloudfront.net/${item.displayImagesUrl}`}
                        alt={item.displayName}
                        className="w-20 h-20 object-cover"
                      />
                    </div>
                  ))}
                </div>

                <div className="mb-4">
                  <p>
                    <strong>Order Placed Time:</strong>{' '}
                    {timeConversion(new Date(order.orderPlacedTime))}
                  </p>
                  <p>
                    <strong>Order Accepted Time:</strong>{' '}
                    {timeConversion(new Date(order.orderAcceptedTime))}
                  </p>
                  <p>
                    <strong>Order Packed Time:</strong>{' '}
                    {timeConversion(new Date(order.orderPackedTime))}
                  </p>
                  <p className="mb-4">
                    <strong>Order Delivered Time:</strong>{' '}
                    {timeConversion(new Date(order.orderDeliveredTime))}
                  </p>
                  <p className="mb-4">
                    <strong>Status:</strong> {order.status}
                  </p>
                  <p className="mb-4">
                    <strong>Cancelled By:</strong> {order.cancelledBy}
                  </p>
                  <p className="mb-4">
                    <strong>Cancelled Reason</strong> {order.cancelledReason}
                  </p>

                  <p>
                    <strong>Order Packed:</strong>{' '}
                    {order.isPacked ? 'Yes' : 'No'}
                  </p>
                  <p>
                    <strong>Order Picked:</strong>{' '}
                    {order.isPicked ? 'Yes' : 'No'}
                  </p>
                  <p className="mb-4">
                    <strong>Order Delivered:</strong>{' '}
                    {order.isDelivered ? 'Yes' : 'No'}
                  </p>
                  <p>
                    <strong>Payment Method:</strong> {order.paymentMethod}
                  </p>
                  <p>
                    <strong>Payment Status:</strong> {order.paymentStatus}
                  </p>
                  <p>
                    <strong>Total Amount:</strong> ₹{order.totalAmount}
                  </p>
                  <p className="mb-4">
                    <strong>Grand Total:</strong> ₹{order.grandTotal}
                  </p>
                  <p>
                    <strong>Prescription Available?:</strong>{' '}
                    {order.prescriptionAvailable ? 'Yes' : 'No'}
                  </p>
                </div>

                <div className="flex justify-between">
                  {order.prescriptionAvailable && (
                    <a
                      href={`https://d2l55457kk8x11.cloudfront.net/${order.prescriptionUrl}`}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      target="_blank"
                      rel="noopener noreferrer" // Security measure
                    >
                      View Prescription
                    </a>
                  )}
                  <a
                    href={`https://d2l55457kk8x11.cloudfront.net/${order.billReceiptImageUrl}`}
                    className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                    target="_blank"
                    rel="noopener noreferrer" // Security measure
                  >
                    Bill Receipt
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Pagination */}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={() => handlePageChange('previous')}
            className={`px-4 py-2 rounded ${
              page === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'
            }`}
            disabled={page === 1}
          >
            Previous
          </button>
          <span className="px-4 py-2 rounded bg-gray-200">{page}</span>
          <button
            onClick={() => handlePageChange('next')}
            className="px-4 py-2 rounded bg-blue-500 text-white"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderStatus;
